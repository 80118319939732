import { Variant } from '@Types/product/Variant'
import { GOOGLE_MAP_API_KEY } from '../general/constants'
export const GEO_MAP_URL = 'https://maps.googleapis.com/maps/api/geocode/json?key=' + GOOGLE_MAP_API_KEY

export const storeDataDefaultValue = {
  id: '',
  storeId: '',
  version: 0,
  versionModifiedAt: '2024-02-21T15:01:19.557Z',
  createdAt: '2024-02-21T15:01:19.557Z',
  lastModifiedAt: '2024-02-21T15:01:19.557Z',
  lastModifiedBy: { clientId: '', isPlatformClient: false },
  createdBy: { clientId: '', isPlatformClient: false },
  storeNumber: '',
  key: '',
  roles: ['ProductDistribution'],
  name: { en: '' },
  custom: {
    type: { typeId: 'type', id: '' },
    fields: {
      availableForInstorePickup: false,
      subtitle: '',
      shipToStore: false,
      lastname: '',
      hours: '',
      storeHours: [
        'monday||10:00||19:00',
        'tuesday||10:00||19:00',
        'wednesday||10:00||19:00',
        'thursday||10:00||19:00',
        'friday||10:00||19:00',
        'saturday||10:00||19:00',
        'sunday||12:00||18:00',
      ],
      title: '',
      active: false,
      additionalHoursText: '',
      firstname: 'DXL',
    },
  },
  address: {
    streetName: '',
    postalCode: '',
    city: '',
    state: '',
    country: '',
    phone: '',
  },
  geoLocation: { coordinates: [], type: 'Point' },
  availableQuantity: 0,
  inventoryMessage: 'NOT_AVAILABLE',
}

export const variantDefaultValue: Variant = {
  id: undefined,
  key: undefined,
  sku: '',
  groupId: undefined,
  attributes: undefined,
  images: [],
  price: undefined,
  prices: [
    {
      custom: undefined,
    },
  ],
  discountedPrice: undefined,
  discounts: [],
  discountId: undefined,
  promotion: undefined,
  discountPercent: undefined,
  priceCustomFields: undefined,
  isOnStock: false,
  availableQuantity: 0,
  availability: {
    id: '',
    backorderlevel: undefined,
    availableQuantity: undefined,
    availabilitydate: undefined,
  },
  channels: undefined,
  availabilityId: undefined,
}

export const storeDetailsBox = {
  display: 'flex',
  alignItems: 'center',
  gap: 'var(--sizes-3, 12px)',
  alignSelf: 'stretch',
}

export const cardBodyText = {
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '120%',
}

export const cardBorder = {
  borderRadius: 'var(--radii-lg, 8px)',
  border: '1px solid shading.200',
  boxShadow: 'none',
}

export const formLabel = {
  color: 'var(--semantic-text-primary, #15191C)',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '120%',
}

export const distances = [
  { value: '25', viewValue: '25 miles' },
  { value: '50', viewValue: '50 miles' },
  { value: '100', viewValue: '100 miles' },
  { value: '200', viewValue: '200 miles' },
]

export const storeHeading = {
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '120%',
}
