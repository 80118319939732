import { createContext, useContext, useMemo, useCallback, useRef, useEffect } from 'react'
import { Account, Address } from 'shared/types/account'
import { mutate } from 'swr'
import { sdk } from 'sdk'
import { CustomerGroup, AccountCTContextValue, RegisterAccount, UpdateAccount } from './types'
import { useRewards } from '../../../composable/components/rewards-club/hooks/useRewards'
import { useFormat } from '../../../helpers/hooks/useFormat'
import { useGetAccount } from '../getAccountContext'

const AccountCTContext = createContext<AccountCTContextValue>(undefined)

export const AccountCTProvider = ({ children }) => {
  const { data: accountData, isLoading: accountDataIsLoading } = useGetAccount()
  const { formatMessage } = useFormat({ name: 'common' })
  const rewards = useRewards()
  const dataRef = useRef<{ loggedIn: boolean; account?: Account; error?: any } | null>(null)

  const data = useMemo(() => {
    if (accountData?.error) {
      dataRef.current = { loggedIn: false, error: accountData.error }
      return dataRef.current
    }

    if (accountData && accountData.account) {
      dataRef.current = { account: accountData.account, loggedIn: true }
      rewards.setIsSubscribed(!!(accountData.account as any).custom?.fields?.loyalty?.isSubscribed)
      rewards.setOptInDate((accountData.account as any).custom?.fields?.loyalty?.optin)

      if ((accountData.account as any).externalId) {
        rewards.setHookReadiness(true)
      }
      return dataRef.current
    }

    dataRef.current = {
      loggedIn: false,
      account: undefined,
      error: accountData?.error,
    }

    return dataRef.current
  }, [accountData])

  const isShouldOptInBeUpdated = useMemo(
    () => rewards?.optInDate !== rewards?.summary?.member?.member_since,
    [rewards?.optInDate, rewards?.summary],
  )

  const handleUpdateOptIn = async (optInDate: string) => {
    const response = await update({ loyaltyOptin: optInDate })
    if (!response?.accountId) {
      throw new Error(formatMessage({ id: 'account.dashboard.profile.submit.error' }))
    }
  }

  useEffect(() => {
    if (isShouldOptInBeUpdated && rewards.summary?.member?.member_since) {
      handleUpdateOptIn(rewards.summary?.member?.member_since)
    }

    // @ts-ignore
    console.log({ error: rewards?.errorSummary })
  }, [rewards.summary])

  const shippingAddresses = useMemo(() => {
    if (!data.account) return []

    return (data.account.addresses ?? []).filter((address) => address.isShippingAddress)
  }, [data.account])

  const billingAddresses = useMemo(() => {
    if (!data.account) return []

    return (data.account.addresses ?? []).filter((address) => address.isBillingAddress)
  }, [data.account])

  const defaultShippingAddress = useMemo(() => {
    return data.account?.addresses?.find((address) => address.isDefaultShippingAddress)
  }, [data.account])

  const defaultBillingAddress = useMemo(() => {
    return data.account?.addresses?.find((address) => address.isDefaultBillingAddress)
  }, [data.account])

  const login = async (email: string, password: string, remember?: boolean): Promise<Account> => {
    const extensions = sdk.composableCommerce

    const payload = {
      email,
      password,
      remember,
    }

    const res = await extensions.account.login(payload)

    mutate('/action/account/getAccount')
    mutate('/action/cart/getCart')

    return res.isError ? ({} as Account) : (res as any).data
  }

  const logout = useCallback(async () => {
    const extensions = sdk.composableCommerce

    await extensions.account.logout()

    mutate('/action/account/getAccount')
    mutate('/action/cart/getCart')
  }, [])

  const register = useCallback(async (account: RegisterAccount): Promise<Account> => {
    const extensions = sdk.composableCommerce

    const res = await extensions.account.register(account)

    mutate('/action/account/getAccount')
    mutate('/action/cart/getCart')

    return res.isError ? ({} as Account) : (res as any).data
  }, [])

  const confirm = useCallback(async (token: string): Promise<Account> => {
    const extensions = sdk.composableCommerce

    const res = await extensions.account.confirm({ token })

    mutate('/action/account/getAccount')

    return res.isError ? ({} as Account) : (res as any).data
  }, [])

  const requestConfirmationEmail = useCallback(async (email: string, password: string): Promise<void> => {
    const extensions = sdk.composableCommerce

    const payload = {
      email,
      password,
    }

    await extensions.account.requestConfirmationEmail(payload)
  }, [])

  const changePassword = useCallback(async (oldPassword: string, newPassword: string): Promise<Account> => {
    const extensions = sdk.composableCommerce

    const res = await extensions.account.changePassword({ oldPassword, newPassword })

    return res.isError ? ({} as Account) : (res as any).data
  }, [])

  const requestPasswordReset = useCallback(async (email: string): Promise<void> => {
    const extensions = sdk.composableCommerce

    const payload = {
      email,
    }

    await extensions.account.requestResetPassword(payload)
  }, [])

  const resetPassword = useCallback(async (token: string, newPassword: string): Promise<Account> => {
    const extensions = sdk.composableCommerce

    const res = await extensions.account.resetPassword({ token, newPassword })

    mutate('/action/account/getAccount')

    return res.isError ? ({} as Account) : (res as any).data
  }, [])

  const update = useCallback(async (account: UpdateAccount): Promise<Account> => {
    const extensions = sdk.composableCommerce

    const res = await extensions.account.updateAccount(account)

    if (!res.isError) {
      mutate('/action/account/getAccount')
    }

    return res.isError ? ({} as Account) : (res as any).data
  }, [])

  const addIsSubscribedType = useCallback(async () => {
    const extensions = sdk.composableCommerce

    const response = await extensions.account.getAccount()

    if (response.isError) return {} as Account

    const res = await sdk.callAction<Account>({
      actionName: 'account/addIsSubscribedType',
      payload: { account: (response as any).data.account },
    })

    mutate('/action/account/getAccount')

    return res.isError ? ({} as Account) : (res as any).data
  }, [])

  const updateSubscription = useCallback(async (isSubscribed: boolean) => {
    const extensions = sdk.composableCommerce

    const response = await extensions.account.getAccount()

    if (response.isError) return {} as Account

    const res = await sdk.callAction<Account>({
      actionName: 'account/updateSubscription',
      payload: { account: (response as any).data.account, isSubscribed },
    })

    mutate('/action/account/getAccount')

    return res.isError ? ({} as Account) : (res as any).data
  }, [])

  const addAddress = useCallback(async (address: Omit<Address, 'addressId'>): Promise<Account> => {
    const extensions = sdk.composableCommerce

    const res = await extensions.account.addAddress(address)

    mutate('/action/account/getAccount')

    return res.isError ? ({} as Account) : (res as any).data
  }, [])

  const addShippingAddress = useCallback(async (address: Omit<Address, 'addressId'>): Promise<Account> => {
    const extensions = sdk.composableCommerce

    const response = await extensions.account.getAccount()

    if (response.isError) return {} as Account

    const res = await sdk.callAction<Account>({
      actionName: 'account/addShippingAddress',
      payload: { account: (response as any).data.account, address },
    })

    mutate('/action/account/getAccount')

    return res.isError ? ({} as Account) : (res as any).data
  }, [])

  const addBillingAddress = useCallback(async (address: Omit<Address, 'addressId'>): Promise<Account> => {
    const extensions = sdk.composableCommerce

    const response = await extensions.account.getAccount()

    if (response.isError) return {} as Account

    const res = await sdk.callAction<Account>({
      actionName: 'account/addBillingAddress',
      payload: { account: (response as any).data.account, address },
    })

    mutate('/action/account/getAccount')

    return res.isError ? ({} as Account) : (res as any).data
  }, [])

  const updateAddress = useCallback(async (address: Address): Promise<Account> => {
    const extensions = sdk.composableCommerce

    const res = await extensions.account.updateAddress(address)

    mutate('/action/account/getAccount')

    return res.isError ? ({} as Account) : (res as any).data
  }, [])

  const removeAddress = useCallback(async (addressId: string): Promise<Account> => {
    const extensions = sdk.composableCommerce

    const res = await extensions.account.removeAddress({ addressId })

    mutate('/action/account/getAccount')

    return res.isError ? ({} as Account) : (res as any).data
  }, [])

  const setDefaultBillingAddress = useCallback(async (addressId: string): Promise<Account> => {
    const extensions = sdk.composableCommerce

    const res = await extensions.account.setDefaultBillingAddress({ addressId })

    mutate('/action/account/getAccount')

    return res.isError ? ({} as Account) : (res as any).data
  }, [])

  const setDefaultShippingAddress = useCallback(async (addressId: string): Promise<Account> => {
    const extensions = sdk.composableCommerce

    const res = await extensions.account.setDefaultShippingAddress({ addressId })

    mutate('/action/account/getAccount')

    return res.isError ? ({} as Account) : (res as any).data
  }, [])

  const updateCustomerGroup = useCallback(async (customerGroupID: string): Promise<Account> => {
    try {
      const res = await sdk.callAction({
        actionName: 'account/updateCustomerGroup',
        payload: { customerGroupID },
      })

      if (!res.isError) {
        mutate('/action/account/getAccount')
        return (res as any).data
      }
    } catch (error) {
      console.error('Error updating customer group:', error)
      return error
    }
  }, [])

  const getAllCustomerGroups = useCallback(async (): Promise<CustomerGroup[]> => {
    try {
      const res = await sdk.callAction<any[]>({
        actionName: 'account/getAllCustomerGroups',
      })
      return res.isError ? [] : (res as any).data
    } catch (error) {
      console.error('Error fetching customer groups:', error)
      return []
    }
  }, [])

  const getCustomerById = useCallback(async (): Promise<Account> => {
    try {
      const res = await sdk.callAction<Account>({
        actionName: 'account/getCustomerById',
      })

      return res.isError ? ({} as Account) : (res as any).data
    } catch (error) {
      console.error('Error fetching account by ID:', error)
      return {} as Account
    }
  }, [])

  return (
    <AccountCTContext.Provider
      value={{
        ...data,
        accountLoading: accountDataIsLoading,
        shippingAddresses,
        billingAddresses,
        defaultShippingAddress,
        defaultBillingAddress,
        login,
        logout,
        register,
        confirm,
        requestConfirmationEmail,
        changePassword,
        requestPasswordReset,
        resetPassword,
        update,
        addIsSubscribedType,
        updateSubscription,
        addAddress,
        addBillingAddress,
        addShippingAddress,
        updateAddress,
        removeAddress,
        setDefaultBillingAddress,
        setDefaultShippingAddress,
        getAllCustomerGroups,
        updateCustomerGroup,
        getCustomerById,
        rewards: { ...rewards },
      }}
    >
      {children}
    </AccountCTContext.Provider>
  )
}

export const useAccountCT = () => {
  const useAccountCTContext = useContext(AccountCTContext)

  if (!useAccountCTContext) {
    throw new Error('useAccountCT must be used within a AccountCTProvider')
  }

  return useAccountCTContext
}
