import { getScriptType } from 'helpers/utils/appendScript'
import Head from 'next/head'
import Script, { handleClientScriptLoad } from 'next/script'
import { useEffect } from 'react'
import { FUNCTIONAL_COOKIE_CATEGORY, GLOBAL_E_CSS_URL, GLOBAL_E_SCRIPT_URL } from '../general/constants'

const GlobalEScript = () => {
  const addGEScript = () => {
    const script =
      '(function () {' +
      "var s = document.createElement('script');" +
      "s.type = 'text/javascript';" +
      's.async = true;' +
      "s.src = '" +
      `${GLOBAL_E_SCRIPT_URL}` +
      "';" +
      "document.getElementsByTagName('head')[0].appendChild(s);" +
      '})();'
    return script
  }
  useEffect(() => {
    handleClientScriptLoad({
      children: addGEScript(),
      type: getScriptType(FUNCTIONAL_COOKIE_CATEGORY),
      id: 'globaleScript',
      className: FUNCTIONAL_COOKIE_CATEGORY,
    })
  }, [])
  return (
    <>
      <Head>
        <link id="GEPIStyles" rel="stylesheet" href={`${GLOBAL_E_CSS_URL}`} />
      </Head>
    </>
  )
}

export default GlobalEScript
