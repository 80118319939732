import { createContext, useContext, useState } from 'react'
import { Order } from '@Types/cart/Order'
import { OrderContextValue } from './types'

const OrderContext = createContext<OrderContextValue>(undefined)

export const OrderProvider = ({ children }) => {
  const [order, setOrder] = useState<Order | null>(null)

  // save estimated delivery date in memory to avoid fetching it again in the order confirmation page
  // DDC-2538
  const [estimateDeliveryDates, setEstimateDeliveryDates] = useState([])

  return (
    <OrderContext.Provider value={{ order, setOrder, estimateDeliveryDates, setEstimateDeliveryDates }}>
      {children}
    </OrderContext.Provider>
  )
}

export const useOrder = () => {
  const useOrderContext = useContext(OrderContext)

  if (!useOrderContext) {
    throw new Error('useOrder must be used within a OrderProvider')
  }

  return useOrderContext
}
