import { CartAddress } from '@Types/account/Address'
import { VerifyLevel } from 'composable/components/types'

export interface CheckoutAddressFormType {
  additional_address_info?: string
  additiona_street_info?: string
  street_name: string
  street_number?: string
  city: string
  state: string
  postcode: string
  country: string
}

export interface CheckoutAddressType {
  id: string
  additional_address_info: string
  additional_street_info: string
  first_name: string
  last_name: string
  phone_number: string
  street_name: string
  street_number: string
  city: string
  state: string
  postcode: string
  country: string
  apartment?: string
}

export interface ExperianAddressType {
  address_line_1: string
  address_line_2: string
  address_line_3: string
  country: string
  locality: string
  postal_code: string
  phone: string
  region: string
  additional_address_info: string
  additional_street_info: string
}
export interface AdqAddressType {
  formattedAddress: {
    addressLine1: string
    addressLine2: string
    addressLine3: string
    locality: string
    province: string
    postalCode: string
    country: string
  }
}
export const isAddressValidate = (verifyLevel: VerifyLevel) => {
  return verifyLevel === VerifyLevel.VERIFIED
}

export const isCheckoutAddressSameAsRecommended = async (
  userInputAddress: CheckoutAddressType,
  experianAddress: ExperianAddressType,
) => {
  const removeDirection = (streetName: string) => {
    return streetName?.replace(/\b(N|S|E|W)\b/g, '')?.trim()
  }

  const isStreetNameSame =
    removeDirection(userInputAddress.street_name) === removeDirection(experianAddress.address_line_1)
  const isCitySame = userInputAddress.city === experianAddress.locality
  const isStateSame = userInputAddress.state === experianAddress.region
  const isPostcodeSame = userInputAddress.postcode === experianAddress.postal_code

  const isSameAddress = isStreetNameSame && isCitySame && isStateSame && isPostcodeSame

  return isSameAddress
}

export const parseCheckoutAddressToString = (checkoutAddress: CheckoutAddressType) => {
  if (!checkoutAddress) return

  const { street_name, additional_address_info, city, state, postcode, additional_street_info } = checkoutAddress

  if (!street_name || !city || !state || !postcode) return

  return additional_address_info
    ? `${street_name}, ${city}, ${state}, ${postcode}, ${additional_street_info}`
    : `${street_name}, ${city}, ${state}, ${postcode}`
}
export const ctAddressToExprerianAddress = (address: CartAddress) => {
  return {
    street_name: address?.streetName || '',
    additional_address_info: address?.additionalAddressInfo || '',
    city: address?.city || '',
    state: address?.state || '',
    postcode: address?.postalCode || '',
    additional_street_info: address?.additionalStreetInfo || '',
  }
}

export const parseExperianAddressToString = (formattedAddress: ExperianAddressType) => {
  if (!formattedAddress) return

  const { address_line_1, address_line_2, locality, region, postal_code } = formattedAddress

  if (!address_line_1 || !locality || !region || !postal_code) return

  return address_line_2
    ? `${address_line_1}, ${address_line_2}, ${locality}, ${region}, ${postal_code}`
    : `${address_line_1}, ${locality}, ${region}, ${postal_code}`
}
