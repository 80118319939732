import { Cart } from '@Types/cart/Cart'
import { ShippingMethod } from '@Types/cart/ShippingMethod'
import { Money } from '@Types/product/Money'
import { OPTION_ADDRESS_SELECTED, ADDRESS_TYPE } from '../constants'

export interface IsShippingMethodFreeAvailableProps {
  shippingOption: ShippingMethod
  cartSubTotal?: Money
  shippingCost: Money
}
export interface InitialAddressValuesI {
  id: string
  additional_address_info: string
  first_name: string
  last_name: string
  phone_number: string
  street_name: string
  street_number: string
  city: string
  state: string
  postcode: string
  country: string
  additional_street_info: string
  apartment: string
  useAddressAsEntered?: boolean
  addressValidated?: boolean
}
export const initAddressValues = {
  id: '',
  additional_address_info: '',
  first_name: '',
  last_name: '',
  phone_number: '',
  street_name: '',
  street_number: '',
  city: '',
  state: '',
  postcode: '',
  country: '',
  additional_street_info: '',
  apartment: '',
  useAddressAsEntered: false,
  addressValidated: false,
}

export const getDiscountedShippingMethodPrice = (
  shippingRate: Money,
  discountedShippingRate: { [key: string]: any },
) => {
  let discountedPrice = undefined

  if (discountedShippingRate) {
    const { type, permyriad, money } = discountedShippingRate.value

    if (type === 'absolute') {
      discountedPrice = Math.max(0, (shippingRate?.centAmount || 0) - (money[0]?.centAmount || 0))
    } else if (type === 'relative') {
      const discountPercentage = permyriad / 10000
      discountedPrice = Math.round((shippingRate?.centAmount || 0) * (1 - discountPercentage))
    }
  }

  return discountedPrice
}

export const getIsFreeShipping = (isFree, shippingRate, discountedShippingRate) => {
  if (isFree || shippingRate?.centAmount === 0 || discountedShippingRate?.centAmount === 0) {
    return true
  } else {
    return false
  }
}

export const getAddressDetails = (id, shippingAddress) => {
  return {
    id: id ?? '',
    additional_address_info: shippingAddress?.additionalAddressInfo || 'shipToStore',
    additional_street_info: shippingAddress?.additionalStreetInfo ?? '',
    first_name: shippingAddress?.firstName ?? '',
    last_name: shippingAddress?.lastName ?? '',
    phone_number: shippingAddress?.phone ?? '',
    street_name: shippingAddress?.streetName ?? '',
    street_number: shippingAddress?.streetNumber ?? '',
    city: shippingAddress?.city ?? '',
    state: shippingAddress?.state ?? '',
    postcode: shippingAddress?.postalCode ?? '',
    country: shippingAddress?.country ?? '',
  }
}

export const linkStyle = {
  fontWeight: '700',
  color: 'var(--semantic-text-primary, #15191C)',
  textDecoration: 'underline',
}

export const isShippingMethodFreeAvailable = ({
  shippingOption,
  cartSubTotal,
  shippingCost,
}: IsShippingMethodFreeAvailableProps) => {
  if (!cartSubTotal || !shippingCost || !shippingOption?.rates || shippingOption.rates.length === 0) {
    return false
  }

  const { centAmount: cartCentAmount } = cartSubTotal

  const { freeAbove } = shippingOption.rates[0]

  if (!cartCentAmount || freeAbove?.centAmount === undefined) {
    return false
  }

  return cartCentAmount - shippingCost.centAmount >= freeAbove.centAmount
}

const getDefaultAddress = (addresses) =>
  addresses?.length > 1 ? addresses?.find((address) => address?.isDefaultShippingAddress) || addresses[0] : addresses[0]

export const handleAddressSelection = ({
  addresses,
  optionAddressSelected,
  setSelected,
  setOptionAddressSelected,
  updateShippingAddress,
  useDefaultAddress = false,
}) => {
  if (!addresses?.length) {
    setOptionAddressSelected({ type: OPTION_ADDRESS_SELECTED.NEW_FORM })
    return
  }

  const optionAddressDefault =
    !useDefaultAddress &&
    optionAddressSelected?.type === OPTION_ADDRESS_SELECTED.SAVED_ADDRESS &&
    optionAddressSelected?.addressId
      ? addresses?.find((address) => address?.addressId === optionAddressSelected?.addressId)
      : getDefaultAddress(addresses)

  setSelected(optionAddressDefault?.addressId)
  updateShippingAddress(optionAddressDefault)
  setOptionAddressSelected({
    type: OPTION_ADDRESS_SELECTED.SAVED_ADDRESS,
    addressId: optionAddressDefault?.addressId,
  })
}

export const getAddressType = (address) => {
  // legacy code from atg
  const city = address?.city?.toLowerCase() || ''
  const state = address?.state || ''
  const streetName = address?.street_name?.toLowerCase() || address?.streetName?.toLowerCase() || ''
  const lastName = address?.last_name ?? ''

  if ((city === 'apo' || city === 'fpo' || city === 'dpo') && (state === 'AA' || state === 'AP' || state === 'AE')) {
    return ADDRESS_TYPE?.MILITARY
  } else if (streetName.includes('po box')) {
    return ADDRESS_TYPE?.PO_BOX
  } else if (lastName?.toLowerCase()?.includes('store')) {
    return ADDRESS_TYPE?.SHIP_TO_STORE
  } else {
    return ADDRESS_TYPE.STANDARD
  }
}

export const isSelectedShippingMethodNotMatch = (
  selectedShippingMethodId: string,
  availableShippingMethods: Cart['availableShippingMethods'],
) => {
  return (
    selectedShippingMethodId &&
    !availableShippingMethods.filter((shippingMethod) => shippingMethod?.shippingMethodId === selectedShippingMethodId)
      .length
  )
}

export const isAnEmptyAddress = (formAddress) => {
  return Object.entries(formAddress)
    .filter(([_, value]) => typeof value === 'string')
    .every(([_, value]) => value === '')
}
