import { useRouter } from 'next/router'
import { useAccountCT, useStore } from 'frontastic/contexts'
import { TEALIUM_SITE_ID } from '../../composable/components/general/constants'
import { useRewards } from '../../composable/components/rewards-club/hooks/useRewards'
import { HomePageInterface, StaticPageInterface } from '../../composable/tealium-interfaces/TealiumPageInterface'

const staticPathRegex = /^\/static\/[^/]+$/
const OTHER_STATIC_URLS = ['no-results', 'emailsignup', 'not-found', 'findmine-pages', 'gift-guides', 'store-pages']

const isStaticPage = (path: string) => {
  if (staticPathRegex.test(path) || OTHER_STATIC_URLS.some((url) => path.indexOf(url) !== -1)) {
    return true
  }
  return false
}
const getPLPCategory = (path: string) => {
  const params = path?.split('/')
  const catWithQuery = params[params.length - 1]
  const category = catWithQuery?.split('?')[0] ?? ''
  return category
}

const getTealiumPageCategory = (path: string) => {
  if (isStaticPage(path)) {
    return 'editorial'
  } else if (path.indexOf('/c/') > -1) {
    return getPLPCategory(path)
  }
  return 'homepage'
}

const getTealiumPageType = (path: string) => {
  if (isStaticPage(path)) {
    return 'static'
  }
  return 'home'
}

const getTealiumSiteSection = (path: string) => {
  if (isStaticPage(path)) {
    return 'static/editorial'
  }
  return 'homepage'
}

const getTealiumPageName = (path: string) => {
  if (isStaticPage(path)) {
    return path
  }
  return 'Home Page'
}

export const useTealium = () => {
  const { account } = useAccountCT()
  const { summary } = useRewards()
  const { selectedStoreData } = useStore()
  const router = useRouter()

  const buildPageWiseUtagData = (path) => {
    const baseTealiumUtagData = buildBaseTealiumUtagData(path)
    if (isStaticPage(path)) {
      const staticPageUtagData: StaticPageInterface = {
        hashed_email: account?.email || undefined,
        customer_first_name: account?.firstName || undefined,
        customer_last_name: account?.lastName || undefined,
        ...baseTealiumUtagData,
      }
      return staticPageUtagData
    }

    const homePageUtagData: HomePageInterface = {
      in_iframe: 0,
      is_confirmation: 0,
      user_loginstatus: !!account?.firstName,
      site_breadcrumb: 'Home',
      ...baseTealiumUtagData,
    }
    return homePageUtagData
  }

  const buildBaseTealiumUtagData = (path) => {
    return {
      site_id: TEALIUM_SITE_ID,
      available_points: summary?.metrics?.point?.balance || '',
      current_tier: summary?.current_tier?.tier_label || '',
      user_mystore: selectedStoreData?.custom?.fields?.lastname || '',
      user_sizeprofile: 'off',
      customer_email: account?.email || undefined,
      customer_postal_code: account?.addresses?.[0]?.postalCode || undefined,
      customer_id: account?.accountId || undefined,
      crm_customer_number: account?.externalId || undefined,
      isThirdParty: 'true',
      page_category: getTealiumPageCategory(path),
      cm_category_id: getTealiumPageCategory(path),
      page_type: getTealiumPageType(path),
      page_name: getTealiumPageName(path),
      site_section: getTealiumSiteSection(path),
      cm_page_id: path,
      page_url: path,
    }
  }

  const constructUtagData = (path, otherParams?: any) => {
    const pageWiseUtagData = buildPageWiseUtagData(path)
    return ['view', { ...pageWiseUtagData, ...otherParams }]
  }

  return {
    constructUtagData,
  }
}
