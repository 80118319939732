import { createContext, useContext, useState, useEffect, useRef, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useQuery } from '@tanstack/react-query'
import { Variant } from '@Types/product/Variant'
import { getCartProductQtyByVariantSku } from 'composable'
import { useLocalStorage } from 'composable/components/hooks/useLocalStorage'
import { storeDataDefaultValue, variantDefaultValue } from 'composable/components/mystore/constants'
import { getCookieByName } from 'composable/components/mystore/utils'
import isEqual from 'lodash/isEqual'
import { usePdp, useCartCT } from 'frontastic'

const formatGetStorePayload = (guestLocCookie: string, selectedStore, currentVariant, isPDPPage: boolean) => {
  let lat: string, lng: string

  // Determine lat/lng based on guestLocCookie or selectedStore
  if (!selectedStore?.address?.city && guestLocCookie) {
    ;[lat, lng] = guestLocCookie.split('|').slice(1, 3)
  } else if (selectedStore?.address?.city !== '') {
    lat = selectedStore.geoLocation?.coordinates[1].toString()
    lng = selectedStore.geoLocation?.coordinates[0].toString()
  }

  // Fetch stores again if PDP page or currentPDPVariant changes
  if (lat && lng) {
    return {
      ...(currentVariant?.sku && { edp: currentVariant.sku }),
      lat,
      lng,
      radius: '25',
      isPDPPage,
    }
  }
  return null
}

const useFetchNearByStores = ({ payload, canFetchStores, productId = '' }) => {
  const { getStore } = usePdp()
  const query = useQuery({
    queryKey: ['useStore-hook', `${String(payload?.lat)},${String(payload?.lng)}`, ...(productId ? [productId] : [])],
    queryFn: async () => {
      try {
        return await getStore(payload)
      } catch (ex) {
        console.error('Error fetching stores:', ex)
        return []
      }
    },
    enabled: canFetchStores,
  })

  return {
    ...query,
    data: query.data,
  }
}

const StoreContext = createContext(undefined)

export const StoreProvider = ({ children }) => {
  const router = useRouter()
  const [isClient, setIsClient] = useState(false)
  const [selectedStoreData, setSelectedStoreData] = useLocalStorage('selectedStoreData', storeDataDefaultValue)
  const [guestLocationCookie, setGuestLocationCookie] = useLocalStorage('guestLocationCookie', '')
  const [selectedStoreListData, setSelectedStoreListData] = useLocalStorage('selectedStoreListData', [])
  const [nearByStoreListDataForFindStores, setNearByStoreListDataForFindStores] = useLocalStorage(
    'nearByStoreListDataForFindStores',
    [],
  )
  const [areAllAttributesSelected, setAreAllAttributesSelected] = useLocalStorage('areAllAttributesSelected', false)
  const [currentPDPVariant, setCurrentPDPVariant] = useLocalStorage<Partial<Variant>>(
    'currentPDPVariant',
    variantDefaultValue,
  )
  const [isInStorePickUp, setIsInStorePickUp] = useLocalStorage('isInStorePickUp', false)
  const [canFetchStores, setCanFetchStores] = useState(false)
  const previousVariantRef = useRef(currentPDPVariant?.sku)
  const isPDPPage = router.asPath.includes('/p/') ?? false
  const { cart } = useCartCT()

  const getStorePayload = useMemo(
    () => formatGetStorePayload(guestLocationCookie, selectedStoreData, currentPDPVariant, isPDPPage),
    [guestLocationCookie, selectedStoreData, currentPDPVariant, isPDPPage],
  )

  const fetchNearByStores = useFetchNearByStores({
    payload: getStorePayload,
    canFetchStores,
    productId: currentPDPVariant?.sku ?? '',
  })

  const isStoreDataLoading = fetchNearByStores.isLoading

  const handleSetSelectedStoreData = async (storeData) => {
    setSelectedStoreData(storeData)
  }

  useEffect(() => {
    setIsClient(true)
    const checkCookie = setInterval(() => {
      const myCookieValue = getCookieByName('GuestLocation')
      if (myCookieValue) {
        setGuestLocationCookie(myCookieValue)
        clearInterval(checkCookie) // Stop polling once the cookie is found
      }
    }, 500) // Check every 500ms
    return () => clearInterval(checkCookie) // Clean up the interval on component unmount
  }, [])

  useEffect(() => {
    const storesResult = Array.isArray(fetchNearByStores.data) ? fetchNearByStores.data : []
    if (storesResult.length > 0 && !isEqual(storesResult, selectedStoreData.id)) {
      setSelectedStoreData(storesResult[0])
      setSelectedStoreListData(storesResult)
      setNearByStoreListDataForFindStores(storesResult)
      setCanFetchStores(false) // Prevent further fetches until triggered again
    }
  }, [fetchNearByStores.data])

  useEffect(() => {
    if (!isPDPPage) {
      setCurrentPDPVariant(variantDefaultValue)
      setAreAllAttributesSelected(false)
      setIsInStorePickUp(false)
    } else {
      if (previousVariantRef.current !== currentPDPVariant?.sku || isInStorePickUp || selectedStoreData.key !== '') {
        setCanFetchStores(true)
        // since the PDP is firing this event multiple times, a cache for each product id will be created
        // and it will be updated only if the user refresh the page
        // this workaround is to prevent multiple calls to the same product id
        previousVariantRef.current = currentPDPVariant?.sku // this will keep changing, react-query will automatically detect if it's a new variant
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPDPPage,
    currentPDPVariant?.sku,
    guestLocationCookie,
    areAllAttributesSelected,
    isInStorePickUp,
    selectedStoreData.storeNumber,
  ])

  // This is specifically needed to pass to the InStorePickUpOptionsWhenStoreIsSelected component.
  // It ensures that pick-up options are displayed only for BOPIS items
  // and not for changes in shipping quantities in the cart.
  const variantCountForBOPISInCartOnly = useMemo(() => {
    return (
      getCartProductQtyByVariantSku({
        variantSku: currentPDPVariant?.sku,
        cart,
        isBOPISProduct: true,
        userSelectedStoreNumber: selectedStoreData?.custom?.fields?.lastname,
      }) ?? 0
    )
  }, [currentPDPVariant?.sku, cart, selectedStoreData?.custom?.fields?.lastname])

  const availableQtyOfSelectedVariant = useMemo(() => {
    return (selectedStoreData?.availableQuantity ?? 0) - variantCountForBOPISInCartOnly <= 0
  }, [selectedStoreData?.availableQuantity, variantCountForBOPISInCartOnly])

  return (
    <StoreContext.Provider
      value={{
        selectedStoreData,
        setSelectedStoreData: handleSetSelectedStoreData,
        guestLocationCookie,
        setGuestLocationCookie,
        selectedStoreListData,
        setSelectedStoreListData,
        nearByStoreListDataForFindStores,
        setNearByStoreListDataForFindStores,
        setCurrentPDPVariant,
        currentPDPVariant,
        isClient,
        isPDPPage,
        areAllAttributesSelected,
        setAreAllAttributesSelected,
        setIsInStorePickUp,
        fetchNearByStores,
        isStoreDataLoading,
        variantCountForBOPISInCartOnly,
        availableQtyOfSelectedVariant,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

export const useStore = () => {
  const useStoreContext = useContext(StoreContext)

  if (!useStoreContext) {
    throw new Error('useStore must be used within a StoreProvider')
  }

  return useStoreContext
}
