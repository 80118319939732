export interface AddrLineItem {
  addressLineOne: string
  addressLineTwo?: string
  addressLineThree?: string
  city: string
  state: string
  zip: string
  apartment?: boolean
}

export interface QasAddrLineItem {
  address?: {
    street_name: string
    city: string
    state: string
    country: string
    postcode: string
  }
  global_address_key?: string
}
export interface PickListItem {
  text: string
  global_address_key: string
  postal_code: string
}
export interface QasRecommendedAddress {
  pickListItem?: PickListItem[]
  addrLineItem?: QasAddrLineItem
}
export interface AddressDataType {
  addrLineItem: AddrLineItem
  pickListItems: PickListItem[]
  verifylevel: VerifyLevel
}

export interface qasAddressParams {
  country: string
  addLayout: string
  searchString: string
  promptSet: string
}

export interface qasAddressResult {
  verifylevel: VerifyLevel
  address: any
}

export interface recommendedAddress {
  region: string
  state: string
  address: string
  address2?: string
  postCode: string
  streetNumber?: string
}

export enum VerifyLevel {
  STREETPARTIAL = 'Street partial',
  NONE = 'None',
  VERIFIED = 'Verified match',
  INTERACTIONREQUIRED = 'Interaction required',
  MULTIPLE = 'Multiple matches',
  PREMISEPARTIAL = 'Premises partial',
}

export interface AppartmentInfo {
  additionalStreetInfo: string
}
