import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormErrorMessageProps,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  Select,
  SelectProps,
  Text,
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'

interface SelectFieldProps {
  selectProps: SelectProps
  label?: string
  callToAction?: JSX.Element
  error?: FieldError
  formLabelProps?: FormLabelProps
  isRequired?: boolean
  children: JSX.Element | JSX.Element[]
  caption?: string
  errorStyle?: FormErrorMessageProps
  showRequiredIndicator?: boolean
}

export const SelectField = ({
  selectProps,
  label,
  callToAction,
  error,
  formLabelProps,
  isRequired = false,
  children,
  caption,
  errorStyle,
  showRequiredIndicator = true,
}: SelectFieldProps) => {
  const { name } = selectProps

  if (!name) return null

  return (
    <FormControl isInvalid={Boolean(error)} isRequired={isRequired} display="flex" flexDirection="column">
      <Flex mb={2}>
        {label && (
          <FormLabel
            htmlFor={name}
            textStyle={'heading-desktop-75'}
            requiredIndicator={
              showRequiredIndicator && (
                <Text as="span" textStyle={'heading-desktop-75'}>
                  *
                </Text>
              )
            }
            color={'text.primary'}
            m={0}
            display="flex"
            alignItems="center"
          >
            <Text textStyle={'heading-desktop-75'} fontWeight="600">
              {label}
            </Text>
          </FormLabel>
        )}
        {callToAction}
      </Flex>
      <Select {...selectProps}>{children}</Select>
      <FormErrorMessage mt={2} {...errorStyle}>
        {error?.message}
      </FormErrorMessage>
      {caption && <FormHelperText fontSize="xs">{caption}</FormHelperText>}
    </FormControl>
  )
}
