import { Cart } from '@Types/cart/Cart'

export const normalizeCartData = (data: any): Cart | undefined => {
  if (data?.cartId) return data as Cart
  if (data?.data?.cartId) return data.data as Cart
  return undefined
}

export const setResToMutate = (res, recalculateCart: boolean = false, mutate) => {
  const normalizeRes = normalizeCartData(res)
  if (normalizeRes?.cartId) {
    mutate(normalizeRes as Cart, recalculateCart)
  } else {
    console.error('Invalid response structure:', res)
  }
}
