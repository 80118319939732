export const parseAttributeArrayIntoString = (
  productAttributes: Array<{ name: string; value: string }>,
  removedSegment3LabelWording?: boolean,
) => {
  const formattedArray = []
  productAttributes?.forEach((item, index) => {
    if (!item?.name || !item?.value) return

    const { name, value } = item

    const formattedKey = name.charAt(0).toUpperCase() + name.slice(1)
    const formattedValue = name === 'color' ? value.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()) : value

    formattedArray.push(`${formattedKey}: ${formattedValue}`)

    if (index < productAttributes.length - 1) {
      formattedArray.push(', ')
    }
  })

  const resultString = formattedArray.join('')

  return resultString.endsWith(', ') ? resultString.slice(0, -2) : resultString
}

export const parseSkuLabel = (sku: string | null | undefined) => {
  if (!sku) return ''
  return `Item #: ${sku}`
}

export const capitalizeFirstLetter = (str: string) => {
  return str
    .split(' ')
    .map((word) => {
      if (word.length === 0) return word
      return word[0].toUpperCase() + word.slice(1).toLowerCase()
    })
    .join(' ')
}

export const sanitizeProductName = (productName: string, defaultSlugName: string) => {
  return productName
    .replaceAll(/[^a-zA-Z]/g, '')
    .toLowerCase()
    .concat('_', defaultSlugName)
}

export const generateSlug = (str?: string) => {
  if (!str) {
    return ''
  }

  return str
    .toLowerCase()
    .replace(/&/g, '')
    .replace(/\s+/g, '-')
    .replace(/[^a-zA-Z0-9-]/g, '')
}
