import { useState } from 'react'
import { UseExpressCheckoutReturn } from './types'

const useExpressCheckout = (): UseExpressCheckoutReturn => {
  const [paypalAlertMessage, setPaypalAlertMessage] = useState<string>('')
  const [isExpressPaymentLoading, setExpressPaymentLoading] = useState<boolean>(false)
  return {
    paypalAlertMessage,
    setPaypalAlertMessage,
    setExpressPaymentLoading,
    isExpressPaymentLoading,
  }
}

export default useExpressCheckout
//TODO : Remove this comment later
