import { useState } from 'react'
import { Box, Text, Button, Icon } from '@chakra-ui/react'
import { ChatIcon } from '@chakra-ui/icons'
import { useRouter } from 'next/router'

const LiveChat = () => {
  const [isHovered, setIsHovered] = useState(false)

  const router = useRouter()
  const path = router.asPath

  const openChatNow = () => {
    const url =
      'https://home-c36.nice-incontact.com/inContact/ChatClient/ChatClient.aspx?poc=43f6a29f-b5a7-4c60-ae30-2e72e59698cb&bu=4600462'
    window.open(url, 'icPatronChatWin', 'location=no,height=630,menubar=no,status-no,width=410')
  }

  const isHidden = path.includes('/checkout') || path.includes('/account')

  if (isHidden) {
    return null // Hide component on these routes
  }

  return (
    <Box
      position="fixed"
      bottom="20px"
      left="20px"
      zIndex="1000"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Button
        leftIcon={<Icon as={ChatIcon} boxSize={isHovered ? '4' : '6'} />} // 👈 Bigger icon initially
        bg="black"
        color="white"
        _hover={{ bg: 'gray.800' }}
        borderRadius="full"
        px={isHovered ? '4' : '2'}
        py="2"
        transition="all 0.3s ease"
        onClick={openChatNow}
      >
        {isHovered && <Text fontSize="sm">Live Chat</Text>}
      </Button>
    </Box>
  )
}

export default LiveChat
