import { useEffect, useMemo, useRef, useState } from 'react'
import { useCartCT } from 'frontastic/hooks'
import { DONATIONS, DONATIONS_AND_GC, GIFT_CARDS } from 'composable'
import { Cart } from '@Types/cart/Cart'
import { getInventoryOfProductBySku } from '../product'
import { SHIPPING_METHODS } from 'composable/components/general/constants'
import { useLocalStorage } from 'composable/components/hooks/useLocalStorage'
import { storeDataDefaultValue } from 'composable/components/mystore/constants'
import { evaluateBackorder } from 'composable/components/horizontal-product-card/utils'
import { UseCartInventoryDataReturn } from './types'

const useCartInventoryData = (): UseCartInventoryDataReturn => {
  const [isAnyBackorderItem, setIsAnyBackOrderItem] = useState(true)
  const [selectedStoreData] = useLocalStorage('selectedStoreData', storeDataDefaultValue)
  const cachedInventoryData = useRef({})
  const { cart } = useCartCT()

  const isBackOrderItem = (qpQuantity, availableForOrder, adjustedIsBackordered, ctAvailableQuantity) => {
    return !(qpQuantity > availableForOrder) && adjustedIsBackordered && qpQuantity > ctAvailableQuantity
  }
  const backOrderItemCheck = async (lineItems: Cart['lineItems']) => {
    for (let lineItem of lineItems) {
      let sku = lineItem.variant?.sku
      let qpQuantity = lineItem?.count
      let availableForOrder = 0
      const isBOPISProduct = lineItem?.custom?.fields?.shippingMethod === SHIPPING_METHODS.PICKUP
      let availabilityStatus = lineItem?.availabilityStatus || ''
      const { isBackordered } = evaluateBackorder(availabilityStatus)
      let adjustedIsBackordered = isBackordered
      let ctAvailableQuantity = 0

      if (!isBOPISProduct) {
        let res = cachedInventoryData.current[sku]
        if (!res) {
          res = await getInventoryOfProductBySku(sku)
          cachedInventoryData.current[sku] = res
        }
        let ctAvailableQuantity = res.availableQuantity ?? 0
        const ctBackOrderLevel = res.backorderlevel ?? 0

        availableForOrder = ctAvailableQuantity + ctBackOrderLevel
        if (!isBackordered && ctBackOrderLevel > 1) {
          adjustedIsBackordered = true
        }
      }
      if (isBackOrderItem(qpQuantity, availableForOrder, adjustedIsBackordered, ctAvailableQuantity)) {
        setIsAnyBackOrderItem(true)
        return
      }
    }
    setIsAnyBackOrderItem(false)
  }
  const isCartAfterpayEligible = useMemo(() => {
    if (cart?.lineItems) {
      let lineItems = cart?.lineItems || []
      if (isAnyBackorderItem) return false
      for (let item of lineItems) {
        if (GIFT_CARDS.includes(String(item?.variant?.key))) return false
        if (item?.variant?.attributes?.isDropShip) {
          return false
        }
      }
      return !lineItems.every((lineItem) => DONATIONS.includes(String(lineItem.variant.key)))
    }
    return true
  }, [cart, isAnyBackorderItem])
  useEffect(() => {
    if (cart?.lineItems) {
      setIsAnyBackOrderItem(true)
      backOrderItemCheck(cart?.lineItems)
    }
  }, [cart?.lineItems])
  return {
    isCartAfterpayEligible,
  }
}

export default useCartInventoryData
