/* eslint-disable import/order */
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from 'composable/chakra'
import { ComposableProvider } from 'composable/components/composable-provider'
import { ThirdPartyScripts } from 'composable/components/dxl-footer/thirdPartyScripts'
import { OneTrust } from 'composable/components/general/components/OneTrust'
import SessionTimeout from 'composable/components/session-timeout'
import { FrontasticProvider } from 'frontastic'
import { AccountCTProvider, LocaleProvider, StoreProvider, AccountContext, OrderProvider } from 'frontastic/contexts'
import Monetate from 'frontastic/tastics/monetate'
import SurflyChat from 'frontastic/tastics/surfly-chat'
import Tealium from 'frontastic/tastics/tealium'
import AffiliateTrackingData from 'frontastic/tastics/tracking-data'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { sdk } from 'sdk'

import '../styles/app.css'
import '../styles/atg/main.css'
import '../styles/components/default-loader.css'
import '../styles/components/slider.css'
import '../styles/themes/default.css'
import '../styles/themes/theme1.css'
import '../styles/themes/theme2.css'
import '../styles/themes/theme3.css'
import dynamic from 'next/dynamic'
import { AuthV2UserProvider } from 'frontastic/contexts/authV2UserContext'
import PayPalProvider from 'components/headless/Paypal/PaypalProvider'
import RewardCertificateProvider from 'frontastic/contexts/RewardCertificateContext'
import GlobalEScript from 'composable/components/dxl-footer/globale-script'
import LiveChat from 'frontastic/tastics/live-chat'

const Drawer = dynamic(() => import('composable/components/cart/cart-drawer/cart-drawer').then((mod) => mod.CartDrawer))

const SignInSignUpGlobal = dynamic(() => import('frontastic/tastics/global-signin-signup'))

const AccountDrawer = dynamic(() =>
  import('composable/components/account/account-drawer').then((mod) => mod.AccountDrawer),
)

function FrontasticStarter({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const path = router.asPath

  useEffect(() => {
    if (window.Yo && path == '/') {
      window.Yo.pubsub.publish({ topic: 'rum/spa/transition/start', message: {} })
    }
  }, [path])

  sdk.configureForNext(router.locale as string)

  return (
    <FrontasticProvider>
      <AccountContext>
        <LocaleProvider>
          <ComposableProvider pageProps={pageProps}>
            <AuthV2UserProvider>
              <StoreProvider>
                <AccountCTProvider>
                  <OrderProvider>
                    <PayPalProvider>
                      <RewardCertificateProvider>
                        <ChakraProvider theme={theme}>
                          {/* <Datadog /> */}
                          <OneTrust />
                          <SessionTimeout />
                          <Monetate />
                          <Component {...pageProps} />
                          <AccountDrawer />
                          <Drawer />
                          <SignInSignUpGlobal />
                          <ThirdPartyScripts />
                          <GlobalEScript />
                          <Tealium />
                          <LiveChat />
                          <SurflyChat />
                          <AffiliateTrackingData />
                        </ChakraProvider>
                      </RewardCertificateProvider>
                    </PayPalProvider>
                  </OrderProvider>
                </AccountCTProvider>
              </StoreProvider>
            </AuthV2UserProvider>
          </ComposableProvider>
        </LocaleProvider>
      </AccountContext>
    </FrontasticProvider>
  )
}

export default appWithTranslation(FrontasticStarter)
