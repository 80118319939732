import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

const AffiliateTrackingData = () => {
  const router = useRouter()

  useEffect(() => {
    const siteID = router.query.siteID as string
    const arrivalTime = Date.now().toString()
    if (siteID) {
      document.cookie = `cmsiteid=${siteID}; path=/; max-age=${30 * 24 * 60 * 60}`
      document.cookie = `cmarrived=${arrivalTime}; path=/; max-age=${30 * 24 * 60 * 60}`
    }
  }, [router.query.siteID])
  return null
}

export default AffiliateTrackingData
