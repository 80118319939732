import { AUTH_ZERO_EXPIRED_TOKEN_MESSAGE, AUTH_ZERO_LOGIN_ERROR_MESSAGE } from '../../constants'
import { ErrorType, GetErrorTypeReturn } from '../types'

export const getErrorType: GetErrorTypeReturn = (errorType: ErrorType) => {
  switch (errorType) {
    case ErrorType.AUTH_ERROR:
      return {
        status: 'error',
        description: AUTH_ZERO_LOGIN_ERROR_MESSAGE,
      }
    case ErrorType.TOKEN_ERROR:
      return {
        status: 'warning',
        description: AUTH_ZERO_EXPIRED_TOKEN_MESSAGE,
      }
  }
}
