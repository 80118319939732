import { useToast } from '@chakra-ui/react'
import { logOutUser } from '../helpers'
import { AUTH_ERROR_TIMEOUT_TRIGGER, AUTH_ZERO_TOKEN_LOCAL_STORAGE_KEY } from '../constants'
import { ErrorType } from './types'
import { getErrorType } from './helpers'

export const useHandleError = () => {
  const toast = useToast()

  const handleLogoutUser = async () => {
    logOutUser()
  }

  const handleAuthError = (errorType: ErrorType) => {
    // removing the token fist to avoid continuous redirect loop
    localStorage.removeItem(AUTH_ZERO_TOKEN_LOCAL_STORAGE_KEY)

    const { status, description } = getErrorType(errorType)

    const tokenExpired = errorType === ErrorType.TOKEN_ERROR
    const authError = errorType === ErrorType.AUTH_ERROR

    toast({
      title: 'Something went wrong',
      description,
      duration: AUTH_ERROR_TIMEOUT_TRIGGER,
      status,
      isClosable: true,
      position: 'top',
      containerStyle: {
        width: '392px',
        marginTop: '100px',
      },
    })

    setTimeout(() => {
      handleLogoutUser()
    }, AUTH_ERROR_TIMEOUT_TRIGGER)
  }

  return { handleAuthError }
}
