export const HANDLING_FEES_TIERS = {
  /*
    Values in US cents
  */
  DEFAULT: {
    key: 'DEFAULT',
    value: 199,
  },
  HC2: {
    key: 'HC2',
    value: 299,
  },
  HC1: {
    key: 'HC1',
    value: 0,
  },
}

export const CALIFORNIA_STATE_CODE = 'CA'
export const HANDLING_FEES_TAX_CATEGORY_KEY = 'standard-tax-category'

/*
  MORE DETAILS HERE: https://destinationxl.atlassian.net/wiki/spaces/DARP/pages/2615508994/Shipping+Handling+Requirements+R3+DotCom

  As of Oct 2024, the handling fees are as follows:
  Most orders will have a handling fee of $1.99 [ DEFAULT ]
  HC2: unused as of Oct 2024
  HC1: for all orders where billing address/shipping address is California, BOPIS (Buy Online, Pick Up In Store), Giftcard, Donation and phone orders
*/
