import { mutate } from 'swr'
import { fetchApiHub } from 'frontastic/lib/fetch-api-hub'

export const getStore = async (obj: any) => {
  if (obj?.lat && obj.radius) {
    try {
      const CTStoresRes = await fetchApiHub(
        '/action/channel/getStore',
        {
          method: 'POST',
        },
        obj,
      )

      if (Array.isArray(CTStoresRes) && CTStoresRes.length > 0) {
        // Remove unnecessary properties before ATG store search call
        delete obj.edp
        delete obj.isPDPPage
        obj.lat = String(obj.lat)
        obj.lng = String(obj.lng)
      }

      return mutate('/action/channel/getStore', CTStoresRes, { revalidate: false })
    } catch (error) {
      console.error('Error fetching stores:', error)
    }
  }
}

type GetStoreByKeyParams = {
  key: string
}

export const getStoreByKey = async ({ key }: GetStoreByKeyParams) => {
  try {
    const CTStoresRes = await fetchApiHub(
      '/action/channel/getStoreByKey',
      {
        method: 'POST',
      },
      { key },
    )

    return mutate('/action/channel/getStoreByKey', CTStoresRes, { revalidate: false })
  } catch (error) {
    console.error('Error fetching stores:', error)
    throw error // Optionally rethrow to handle the error further up
  }
}
