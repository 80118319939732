import { getCookieByName } from 'composable/components/mystore/utils'
import { createContext, useContext, useEffect, useState } from 'react'

const LocaleContext = createContext(undefined)
export const LocaleProvider = ({ children }) => {
  const [country, setCountry] = useState('US')
  const [currency, setCurrency] = useState('USD')
  const [countryName, setCountryName] = useState('United States')
  const [region, setRegion] = useState('United States')
  const [cookieValue, setCookieValue] = useState('')

  useEffect(() => {
    const checkCookie = () => {
      const newValue = getCookieByName('GlobalE_Data')
      if (newValue !== cookieValue) {
        setCookieValue(newValue)
        clearInterval(interval)
      }
    }
    const interval = setInterval(checkCookie, 500)
    return () => clearInterval(interval) // Stop polling once the cookie is found
  }, [cookieValue])

  useEffect(() => {
    if (cookieValue) {
      try {
        const parsedCookie = JSON.parse(cookieValue)
        setCountry(parsedCookie.countryISO)
      } catch (error) {
        console.error('Error parsing cookie:', error)
      }
    }
  }, [cookieValue])

  return (
    <LocaleContext.Provider
      value={{
        country,
        currency,
        countryName,
        region,
        setCountry,
        setCurrency,
        setCountryName,
        setRegion,
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}

export const useLocale = () => {
  const useLocaleContext = useContext(LocaleContext)

  if (!useLocaleContext) {
    throw new Error('useLocale must be used within a LocaleProvider')
  }

  return useLocaleContext
}
