import { CartAddress } from '@Types/account/Address'
import { Cart, addItemProps } from '@Types/cart/Cart'
import { Discount } from '@Types/cart/Discount'
import { LineItem } from '@Types/cart/LineItem'
import { Payment } from '@Types/cart/Payment'
import { OrderFilter } from '@Types/query/OrderFilter'
import { mutate } from 'swr'
import { CustomFields } from 'frontastic/hooks/useCart/types'
import { fetchApiHub } from 'frontastic/lib/fetch-api-hub'

export type CartDetails = {
  account?: { email: string }
  shipping?: CartAddress
  billing?: CartAddress
  addressSetByPosBroker?: boolean
  removeInitializedByStoreMobileDevice?: boolean
  savedAndSuspendedCheckout?: boolean
  smsAlertOptedIn?: boolean
  emailAlertOptedIn?: boolean
  smsAlertOptedInNumber?: number
}

export const getCart = async (userSelectedStoreId: string) => {
  try {
    const res = await fetchApiHub(
      '/action/cart/getCart',
      {
        method: 'POST',
      },
      {
        userSelectedStoreId: userSelectedStoreId,
      },
    )
    mutate('/action/cart/getCart', res, { revalidate: false })
    return res
  } catch (error) {
    console.error('Error API: getCart', error)
  }
}

export const getCheckoutCartInfoSession = async () => {
  try {
    const res = await fetchApiHub(
      '/action/globalECart/getCheckoutCartInfoSession',
      {
        method: 'POST',
      },
      {},
    )
    mutate('/action/globalECart/getCheckoutCartInfoSession', res, { revalidate: false })
    return res
  } catch (error) {
    console.error('Error API: getCart', error)
  }
}

//use for Global E
export const getCheckoutCartInfo = async (merchantCartToken: string, merchantGuid: string) => {
  try {
    const res = await fetchApiHub(
      '/action/globalECart/getCheckoutCartInfo',
      {
        method: 'POST',
      },
      {
        MerchantCartToken: merchantCartToken,
        MerchantGUID: merchantGuid,
      },
    )
    mutate('/action/globalECart/getCheckoutCartInfo', res, { revalidate: false })
    return res
  } catch (error) {
    console.error('Error API: getCart', error)
  }
}

export const clearGlobalECart = async () => {
  try {
    const res = await fetchApiHub('/action/globalECart/clearGlobalECart', {
      method: 'POST',
    })
    mutate('/action/cart/getCart', res, { revalidate: false })
    return res
  } catch (error) {
    console.error('Error API: getCart', error)
  }
}

export const checkout = async () => {
  try {
    const res = await fetchApiHub('/action/cart/checkout', {
      method: 'POST',
    })
    await mutate('/action/cart/getCart', res)
    return { order: res }
  } catch (err) {
    console.log('Error', err)
  }
}

export const orderHistory = async (filter?: OrderFilter) => {
  return await fetchApiHub('/action/cart/getOrders', { method: 'POST' }, { filter })
}

export const getAllCartDiscountCodes = async () => {
  return await fetchApiHub('/action/cart/getCartDiscounts', { method: 'POST' })
}

export const getProjectSettings = async () => {
  return await fetchApiHub('/action/project/getProjectSettings')
}

export const getShippingMethods = async () => {
  return await fetchApiHub('/action/cart/getShippingMethods')
}

export const initializeCart = async (initializedByStoreMobileDevice?: boolean) => {
  return await fetchApiHub('/action/sts/initializeCart', { method: 'POST' }, { initializedByStoreMobileDevice })
}

export const setInstoreCart = async (cartId: string, associateId: string, storeId: string) => {
  if (!associateId && !storeId) {
    return await fetchApiHub(`/action/cart/setInstoreCart?cartId=${cartId}`)
  } else {
    return await fetchApiHub(
      `/action/cart/setInstoreCart?cartId=${cartId}&associateId=${associateId}&storeId=${storeId}`,
    )
  }
}

export const addItemToCart = async (payload: addItemProps | {}) => {
  const res = await fetchApiHub(
    '/action/cart/addToCart',
    {
      method: 'POST',
    },
    {
      ...payload,
    },
  )
  return mutate('/action/cart/getCart', res, { revalidate: true })
}

export const removeItemFromCart = async (lineItemId: string) => {
  const payload = {
    lineItem: { id: lineItemId },
  }
  const res = await fetchApiHub(
    '/action/cart/removeLineItem',
    {
      method: 'POST',
    },
    payload,
  )
  mutate('/action/cart/getCart', res, { revalidate: false })
}

export const updateItem = async (lineItemId: string, newQuantity: number) => {
  const payload = {
    lineItem: {
      id: lineItemId,
      count: newQuantity,
    },
  }

  const res = await fetchApiHub(
    '/action/cart/updateLineItem',
    {
      method: 'POST',
    },
    payload,
  )
  return mutate('/action/cart/getCart', res, { revalidate: false })
}

export const updateCartItems = async (lineItems: LineItem[]) => {
  const payload = {
    lineItems: lineItems,
  }

  const res = await fetchApiHub(
    '/action/cart/updateCartItems',
    {
      method: 'POST',
    },
    payload,
  )
  mutate('/action/cart/getCart', res, { revalidate: false })
}

export const updateCartCT = async (payload: CartDetails): Promise<Cart> => {
  const res = await fetchApiHub(
    '/action/cart/updateCart',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  )
  if (!res?.cartId) {
    return
  }

  mutate('/action/cart/getCart', res, { revalidate: false })
  return res
}

export const setShippingMethodCT = async (shippingMethodId: string) => {
  const payload = {
    shippingMethod: {
      id: shippingMethodId,
    },
  }

  const res = await fetchApiHub(
    `/action/cart/setShippingMethod?shippingMethodId=${shippingMethodId}`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  )
  mutate('/action/cart/getCart', res, { revalidate: false })
}

export const redeemDiscountCode = async (code: string) => {
  const payload = {
    code: code,
  }
  const res = await fetchApiHub(
    `/action/cart/redeemDiscount`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  )

  if (!res.isError && ((res as any).data as Cart).cartId) {
    mutate('/action/cart/getCart', res, { revalidate: false })
  }

  return res
}

export const removeDiscountCode = async (discount: Discount) => {
  const payload = {
    discountId: discount.discountId,
  }
  const res = await fetchApiHub(
    '/action/cart/removeDiscount',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  )

  if (!res.isError && ((res as any).data as Cart).cartId) {
    mutate('/action/cart/getCart', res, { revalidate: false })
  }

  return res
}

export const getOrder = async (orderId: string) => {
  const payload = {
    orderId,
  }

  return await fetchApiHub(
    '/action/cart/getOrder',
    {
      method: 'POST',
    },
    payload,
  )
}

export const getOrderByOmsOrderNumber = async (orderNumber: string) => {
  const payload = {
    orderNumber,
  }

  return await fetchApiHub(
    '/action/cart/getOrderByOmsOrderNumber',
    {
      method: 'POST',
    },
    payload,
  )
}

export const getCheckoutOrder = async () => {
  return await fetchApiHub('/action/cart/getCheckoutOrder')
}

export const addPaymentByInvoice = async (payment: Payment) => {
  const payload = {
    payment,
  }
  const res = await fetchApiHub(
    '/action/cart/addPaymentByInvoice',
    {
      method: 'POST',
    },
    payload,
  )
  mutate('/action/cart/addPaymentByInvoice', res)
  return res
}

export const recalculateCart = async () => {
  const res = await fetchApiHub(
    '/action/cart/recalculateCart',
    {
      method: 'POST',
    },
    {},
  )
  mutate('/action/cart/getCart', res, { revalidate: false })
}

export const confirmCart = async (cartId: string) => {
  const res = fetchApiHub(`/action/cart/confirmCart?cartId=${cartId}`)
  return mutate('/action/cart/getCart', res, { revalidate: false })
}

export const prepareForSubmit = async (payload: any) => {
  const res = await fetchApiHub(
    `/action/cart/prepareForSubmit`,
    {
      method: 'POST',
    },
    payload,
  )
  return res
}

export const placeOrder = async (payload: any) => {
  const res = await fetchApiHub(
    `/action/cart/placeOrder`,
    {
      method: 'POST',
    },
    payload,
  )
  //recalculateCart()
  await mutate('/action/account/getAccount', res)

  return res
}

export const setOrUpdateLineItemCustomType = async (lineItemId: string, customData: CustomFields) => {
  const payload = {
    lineItemId,
    customData,
  }

  const res = await fetchApiHub(
    '/action/cart/setOrUpdateLineItemCustomType',
    {
      method: 'POST',
    },
    payload,
  )
  mutate('/action/cart/getCart', res, { revalidate: false })
}

export const getCartDiscountByKey = async (key: string) => {
  const payload = {
    key,
  }

  const res = await fetchApiHub(
    '/action/cart/getCartDiscountByKey',
    {
      method: 'POST',
    },
    payload,
  )

  return res
}

export const setHandlingFees = async () => {
  const res = await fetchApiHub(
    '/action/cart/setHandlingFees',
    {
      method: 'POST',
    },
    {},
  )
  await mutate('/action/cart/getCart', res, { revalidate: false })
  return res
}

export const removeNotMatchingDiscountCodes = async () => {
  const res = await fetchApiHub(
    '/action/cart/removeNotMatchingDiscountCodes',
    {
      method: 'POST',
    },
    {},
  )
  await mutate('/action/cart/getCart', res, { revalidate: false })
  return res
}
export const getItemsEstimateDeliveryDate = async (payload: any) => {
  try {
    const res = await fetchApiHub(
      '/action/orderSearch/getItemsEstimateDeliveryDate',
      {
        method: 'POST',
      },
      payload,
    )
    await mutate('/action/orderSearch/getItemsEstimateDeliveryDate', res, { revalidate: false })
    return res
  } catch (err) {
    console.log('Error', err)
  }
}

export const addItemShippingAddress = async (addressKey: string, address: CartAddress) => {
  const payload = {
    addressKey,
    address,
  }

  const res = await fetchApiHub(
    '/action/cart/addItemShippingAddress',
    {
      method: 'POST',
    },
    payload,
  )
  mutate('/action/cart/getCart', res, { revalidate: false })
}

export const removeItemShippingAddress = async (addressKey: string) => {
  const payload = {
    addressKey,
  }

  const res = await fetchApiHub(
    '/action/cart/removeItemShippingAddress',
    {
      method: 'POST',
    },
    payload,
  )
  mutate('/action/cart/getCart', res, { revalidate: false })
}

export const setLineItemShippingDetails = async (addressKey: string, quantity: number) => {
  const payload = {
    addressKey,
    quantity,
  }

  const res = await fetchApiHub(
    '/action/cart/setLineItemShippingDetails',
    {
      method: 'POST',
    },
    payload,
  )
  mutate('/action/cart/getCart', res, { revalidate: false })
}

// export const removeItemShippingAddress = async (addressKey: string) => {
//   const payload = {
//     addressKey,
//   }

//   const res = await fetchApiHub(
//     '/action/cart/removeItemShippingAddress',
//     {
//       method: 'POST',
//     },
//     payload,
//   )
//   mutate('/action/cart/getCart', res, { revalidate: false })
// }
