import { createContext, useContext } from 'react'
import useSWR from 'swr'
import { GetAccountResult, getAccount } from 'frontastic/actions/account'

interface GetAccountContextType {
  data: GetAccountResult
  isLoading: boolean
}
const GetAccountContext = createContext<GetAccountContextType>(undefined)

const revalidateOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: true,
}

export const AccountContext = ({ children }) => {
  const result = useSWR('/action/account/getAccount', async () => await getAccount(), revalidateOptions)

  return (
    <GetAccountContext.Provider
      value={{
        data: result?.data,
        isLoading: result.isLoading,
      }}
    >
      {children}
    </GetAccountContext.Provider>
  )
}

export const useGetAccount = () => {
  const useGetAccountContext = useContext(GetAccountContext)

  if (!useGetAccountContext) {
    throw new Error('useGetAccount must be used within a AccountContext')
  }

  return useGetAccountContext
}
