import Decimal from 'decimal.js'

const upperRound = (number: number, fxRate: number, lcp: number, fxScale: number) => {
  let price = new Decimal(number.toString())

  const fxRateMultiplier = new Decimal(fxRate.toString())
  const lcpMultiplier = new Decimal(lcp.toString())

  price = price.mul(fxRateMultiplier)
  price = price.mul(lcpMultiplier)

  price = price.toDecimalPlaces(fxScale > 0 ? fxScale : 0, Decimal.ROUND_HALF_UP)

  return price.toFixed(2)
}

export const useBorderFreeCurrencyConversion = (price: number) => {
  return price.toFixed(2)
}

export const dollarsToCents = function (dollars: number): number {
  if (isNaN(dollars)) {
    throw new Error('Input must be a valid number')
  }
  return Math.round(dollars * 100)
}
