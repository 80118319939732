import { useEffect, useState } from 'react'
import { SHIPPING_METHODS } from 'composable/components/general/constants'
import { getCookieByName } from 'composable/components/mystore/utils'
import { COUNTRIES } from 'composable/helpers'
import { fetchAdqAddressSuggestion, formatAdqAddress } from 'frontastic/actions/experian'

const useAdqAddress = ({ setFormValues, streetNameRef }) => {
  const [suggestions, setSuggestions] = useState([])
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1)

  const getCountryCode = (countryName: string) => {
    const country = COUNTRIES.find((c) => c.name === countryName)
    return country ? country.code : countryName
  }

  const onStreetNameChange = async (value: string) => {
    if (!value) {
      setSuggestions([])
      return
    }

    let guestLocation: string | string[] = getCookieByName('GuestLocation') || ''
    guestLocation = guestLocation.split('|')
    let location = ''
    if (guestLocation.length >= 3) {
      location += guestLocation[1] + ',' + guestLocation[2]
    }
    try {
      const suggestions = await fetchAdqAddressSuggestion(value, location)

      setSuggestions(suggestions)
    } catch (error) {
      console.error('Error fetching address suggestions:', error)
    }
  }

  const handleSuggestionClick = async (suggestedAddressGlobalKey: string) => {
    try {
      const formattedAddress = await formatAdqAddress(suggestedAddressGlobalKey)
      const countryCode = getCountryCode(formattedAddress.country)

      setFormValues({ ...formattedAddress, country: countryCode, additional_address_info: SHIPPING_METHODS.SHIPPING })
    } catch (error) {
      console.error('Error formatting address:', error)
    } finally {
      setSuggestions([])
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (streetNameRef.current && !streetNameRef.current.contains(event.target)) {
        setSuggestions([])
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [streetNameRef])

  return {
    onStreetNameChange,
    suggestions,
    handleSuggestionClick,
    selectedSuggestionIndex,
    setSelectedSuggestionIndex,
  }
}

export default useAdqAddress
