import { UseToastOptions } from '@chakra-ui/react'

export enum ErrorType {
  AUTH_ERROR = 'AUTH_ERROR',
  TOKEN_ERROR = 'TOKEN_ERROR',
}

type ToastErrorStatus = UseToastOptions['status']

export interface GetErrorTypeReturn {
  (errorType: ErrorType): { status: ToastErrorStatus; description: string }
}
