import { createContext, useContext, useState, useEffect } from 'react'
import {
  AUTH_ZERO_TOKEN_LOCAL_STORAGE_KEY,
  AUTH_ZERO_TOKEN_EXPIRED_ERROR,
  AUTH_ZERO_TOKEN_LOGIN_ERROR_MESSAGE,
  AUTH_ZERO_CURRENT_USER_ID,
  AUTH_ZERO_COOKIE_NAME,
  AUTH_ZERO_ENC_KEY,
} from './constants'
import { useRouter } from 'next/router'
import { checkTokenExpiration, decodeJWT, handleShallowRouteReplace, logOutUser, getTokenAuthProvider } from './helpers'
import { Auth0ProviderType, AuthV2UserContextType, AuthZeroAccessToken, DecodedToken } from './types'
import { useHandleError } from './hooks/useHandleError'
import { ErrorType } from './hooks/types'
import cookieCutter from 'cookie-cutter'
import CryptoJS from 'crypto-js'

const AuthV2UserContext = createContext<AuthV2UserContextType>(undefined)

export const AuthV2UserProvider = ({ children }) => {
  const [authZeroAccessToken, setAuthZeroAccessToken] = useState<AuthZeroAccessToken>(null)
  const [currentAuthProvider, setCurrentAuthProvider] = useState<Auth0ProviderType>(null)
  const router = useRouter()
  const { handleAuthError } = useHandleError()
  const [currentUserId, setCurrentUserId] = useState<string | null>(null)

  const handleLogoutUser = async () => {
    logOutUser()
  }

  const checkAuthZeroToken = async () => {
    const authZeroCookie = cookieCutter.get(AUTH_ZERO_COOKIE_NAME)
    if (!authZeroCookie) {
      // Anonymous User, just return
      return
    }
    const decryptedCookie = CryptoJS.AES.decrypt(authZeroCookie, AUTH_ZERO_ENC_KEY).toString(CryptoJS.enc.Utf8)

    if (decryptedCookie) {
      const parsedToken = JSON.parse(decryptedCookie) as AuthZeroAccessToken
      setAuthZeroAccessToken(parsedToken)

      // 2. Decode token and check if email is present, check expiration
      const decodedToken = decodeJWT(parsedToken.id_token) as DecodedToken

      if (decodedToken) {
        const expiration = decodedToken.decodedPayload.exp
        const isExpired = checkTokenExpiration(expiration)
        const currentProvider = getTokenAuthProvider(decodedToken) as Auth0ProviderType

        setCurrentAuthProvider(currentProvider || null)
        setCurrentUserId(decodedToken.decodedPayload.sub)

        if (isExpired) {
          // expired Token, redirect to Logout
          handleAuthError(ErrorType.TOKEN_ERROR)
          logOutUser()
        }
      }
    } else {
      //problem decrypting cookie... logout as well
      console.error('Error Parsing AuthZero Cookie')
      handleAuthError(ErrorType.AUTH_ERROR)
      logOutUser()
    }
  }

  useEffect(() => {
    const query = router?.query
    const { loginError, code, checkout, ...restQuery } = query
    if (loginError) {
      handleShallowRouteReplace(router, restQuery)
      handleAuthError(ErrorType.AUTH_ERROR)
    }
    if (code || checkout) {
      // ensure code and checkout information used in login are not present in url
      handleShallowRouteReplace(router, restQuery)
    }
  }, [router])

  // Check user login status on mount
  useEffect(() => {
    checkAuthZeroToken()
  }, [])

  return (
    <AuthV2UserContext.Provider
      value={{
        authZeroAccessToken,
        handleLogoutUser,
        currentAuthProvider,
        currentUserId,
      }}
    >
      {children}
    </AuthV2UserContext.Provider>
  )
}

export const useAuthV2User = () => {
  const userDetails = useContext(AuthV2UserContext)

  if (!userDetails) {
    throw new Error('useAuthV2User must be used within a AuthV2UserProvider')
  }

  return userDetails
}
