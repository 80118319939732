import { Cart } from '@Types/cart/Cart'
import { MarigoldApiOfferResponses } from '@Types/marigold-api/MarigoldApiOfferResponses'
import { format, parseISO, isAfter, isEqual } from 'date-fns'
import { HANDLING_FEES_TIERS, CALIFORNIA_STATE_CODE, getCartSummaryAmounts } from 'composable'
import { SHIPPING_METHODS } from 'composable/components/general/constants'
import { CheckoutStateType } from '../../types'

export const getHandlingFeesFromCartAndCheckoutState = (
  cart: Partial<Cart> | undefined,
  checkoutState: CheckoutStateType,
) => {
  if (
    !cart ||
    //!cart.billingAddress ||
    !cart.shippingAddress ||
    //Object.keys(cart?.billingAddress).length === 0 ||
    Object.keys(cart?.shippingAddress).length === 0
  ) {
    return undefined
    /*
      Show "Calculated at Checkout" till shipping/billing address is not available
      Returning "undefined" will ensure that instead of $0, "Calculated at Checkout" will be shown
    */
  }

  const isBillingOrShippingCalifornian = checkIsBillingOrShippingCalifornian(checkoutState)
  const isCartLineItemsHC1TierItem = checkSingleCartLineItemIsHC1Tier(cart)

  if (isBillingOrShippingCalifornian || isCartLineItemsHC1TierItem) {
    return HANDLING_FEES_TIERS.HC1.value
  } else {
    return HANDLING_FEES_TIERS.DEFAULT.value
  }
}

const checkIsBillingOrShippingCalifornian = (checkoutState: CheckoutStateType) => {
  if (
    !checkoutState ||
    // Object.keys(checkoutState?.billing_address).length === 0 ||
    Object.keys(checkoutState?.shipping_address).length === 0 ||
    //checkoutState?.billing_address?.state === '' ||
    checkoutState?.shipping_address?.state === ''
  )
    return false

  return (
    checkoutState?.billing_address?.state === CALIFORNIA_STATE_CODE ||
    checkoutState?.shipping_address?.state === CALIFORNIA_STATE_CODE
  )
}

const checkSingleCartLineItemIsHC1Tier = (cart: Partial<Cart> | undefined) => {
  if (!cart || !cart?.lineItems || !cart?.lineItems?.length || cart?.lineItems?.length !== 1) {
    return false

    /*
    Check if cart has only 1 lineItem AND
    Check if that single lineItem is HC1 tier

    A mixed cart with HC1 tier item and non-HC1 tier item will not be considered as HC1 tier cart

    BOPIS =  Buy Online, Pick Up In Store
  */
  }

  const lineItem = cart?.lineItems?.[0]
  const productSlug = lineItem?.slug?.includes('donation') || lineItem?.slug?.includes('gift') || false
  const isBOPIS = lineItem?.custom?.fields?.shippingMethod === SHIPPING_METHODS.PICKUP

  return productSlug || isBOPIS
}

// Function for handling stripe payment
export const handleStripePayment = async (context, cart, addPaymentByInvoice) => {
  const { total } = getCartSummaryAmounts(cart)

  const { data: paymentIntent } = await context.__createPaymentIntent({
    confirm: true,
    return_url: `${window.location.origin}/cart`,
    customer: context.payment.stripeData?.customerId,
    payment_method: context.payment.stripeData.paymentMethodId,
    amount: total,
    currency: 'USD', // TODO: get from cart
  })

  const transactions = paymentIntent.charges.data?.map((charge) => {
    return {
      timestamp: new Date(paymentIntent.created * 1000).toISOString(),
      type: 'Charge',
      state: charge.status === 'succeeded' ? 'Success' : 'Pending',
      amount: {
        currencyCode: charge.currency?.toUpperCase(),
        centAmount: charge.amount,
      },
      interactionId: charge.id,
    }
  })

  await addPaymentByInvoice({
    paymentId: paymentIntent.id,
    paymentStatus: paymentIntent.status === 'succeeded' ? 'paid' : 'pending',
    paymentMethod: 'card',
    amountPlanned: {
      currencyCode: paymentIntent.currency?.toUpperCase(),
      centAmount: paymentIntent.amount ?? 0,
      fractionDigits: 2,
    },
    paymentProvider: 'stripe',
    transactions: transactions,
    id: cart.cartId,
  })
}

export const formateDate = (date: string) => {
  if (!date) return date
  let dateStr = new Date(date)
  return format(dateStr, 'MMM d, yyyy')
}
export const isUsableCertificate = (usageDate: string, isInactive: boolean, isAlreadyUsed: boolean) => {
  const givenDate = parseISO(usageDate)
  const currentDate = new Date()
  return (isAfter(givenDate, currentDate) || isEqual(givenDate, currentDate)) && !isInactive && !isAlreadyUsed
}
export const mapRewardCertificateValue = (datas?: MarigoldApiOfferResponses['responses']) => {
  return (
    datas?.map((response) => {
      response.certificate.end_date = response.usage_end_date
      response.certificate.displayExpiryDate = formateDate(response.usage_end_date as any)
      let business_value: string = response?.offer?.rcBalance || ''
      let balance = parseFloat(business_value || '')
      response.offer.business_value = response.offer.rcBalance
      response.offer.displayBalance = `$${balance % 1 === 0 ? balance.toFixed(0) : balance.toString()}`
      return response
    }) || []
  )
}
