import { useEffect } from 'react'
import { useCartCT } from 'frontastic'
import { useAccountCT } from 'frontastic/contexts'
import { SESSION_TIMEOUT_DURATION } from './general/constants'

const SessionTimeout = () => {
  const { account } = useAccountCT()
  const { cart, recalculateCart } = useCartCT()

  let userActivity: any
  const callTimeout = (request) => {
    if (request?.baseURL?.indexOf('public/v1') > -1) {
      if (
        (account?.firstName || (cart && cart?.lineItems?.length !== 0)) &&
        window.location?.href?.indexOf('st=y') == -1
      ) {
        userActivity = setTimeout(() => {
          clearTimeout(userActivity)
          window.location.href = window.location.origin + '?st=y'
        }, SESSION_TIMEOUT_DURATION)
      }
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.get('st') === 'y') {
      recalculateCart()
    }
  }, [])

  return null
}

export default SessionTimeout
