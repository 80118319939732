export const ADDRESS_TYPE = {
  STANDARD: 'STANDARD',
  PO_BOX: 'POBOX',
  MILITARY: 'MILITARY',
  SHIP_TO_STORE: 'shipToStore',
  US_Outlying_Territory: 'Standard Delivery US Outlying Territory',
  APO_DPO_FPO: 'Priority Mail for PO/APO/FPO',
}

export const OPTION_ADDRESS_SELECTED = {
  NEW_FORM: 'newForm',
  SAVED_ADDRESS: 'savedAddress',
  SHIP_TO_STORE: 'shipToStore',
}

export const MAX_LENGTH_USER_NAME = 35
export const MAX_LENGTH_ADDRESS = 35
export const MAX_LENGTH_ZIP_CODE = 10
export const MAX_LENGTH_PHONE_NUMBER = 10
export const MAX_LENGTH_PHONE_NUMBER_W_DASHES = 12 // Must account for dashes in the UI
export const BOPIS = 'pickup'
