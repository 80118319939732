import { useState } from 'react'

export const useToolTip = () => {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleTouchStart = () => {
    setShowTooltip(!showTooltip)
  }
  const handleTouchEnd = () => {
    setTimeout(() => setShowTooltip(false), 3000)
  }
  const handleMouseEnter = () => {
    setShowTooltip(true)
  }
  const handleMouseLeave = () => {
    setShowTooltip(false)
  }

  return {
    showTooltip,
    handleTouchStart,
    handleTouchEnd,
    handleMouseEnter,
    handleMouseLeave,
  }
}
