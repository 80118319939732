// AUTH ZERO CONSTANTS

export const AUTH_ZERO_TOKEN_LOCAL_STORAGE_KEY = 'auth0_access_token'
export const AUTH_ZERO_CURRENT_USER_ID = 'user_id_auth_zero' //

export const AUTH_ZERO_DOMAIN = process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL
export const AUTH_ZERO_CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID
export const ENABLE_AUTH_ZERO_LOGIN = process.env.NEXT_PUBLIC_ENABLE_AUTH0 === 'true'
export const AUTH_ZERO_TOKEN_EXPIRED_ERROR = '==== TOKEN EXPIRED ==== Auth Zero Token has expired'
export const AUTH_ZERO_EXPIRED_TOKEN_MESSAGE = 'Token has expired. Please log in again.'
export const AUTH_ZERO_LOGIN_ERROR_MESSAGE = 'An error occurred. Please try again later.'
export const AUTH_ZERO_TOKEN_LOGIN_ERROR_MESSAGE = 'LOGIN ERROR FROM /authV2UserContext/index.tsx'
export const AUTH_ZERO_REQUEST_SCOPE = 'openid profile email offline_access' // https://auth0.com/docs/secure/tokens/refresh-tokens/get-refresh-tokens
export const AUTH_ZERO_COOKIE_NAME = 'auth_zero'
export const AUTH_ZERO_ENC_KEY = 'AccessTokenFromAuthZero' // Todo move this to environment variable to improve security

export const AUTH_ZERO_MULTIPLE_RUNS_SESSION_RETRIES = 2
export const AUTH_ZERO_MULTIPLE_RUNS_SESSION_INTERVAL = 200

export const AUTH_ERROR_TIMEOUT_TRIGGER = 2000
export const ACCOUNT_REDIRECT_URI = '/account'
export const AUTH_ZERO_LOGOUT_KEY = 'auth0-logout'
