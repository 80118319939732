import React, { useEffect, useRef, useState } from 'react'
import { Stack, Text, Box, Radio, Spacer, Flex, useDisclosure, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Account } from '@Types/account/Account'
import { Address, CartAddress } from '@Types/account/Address'
import {
  ctAddressRefinement,
  ExperianAddressType,
  getCheckoutAddress,
  isAddressValidate,
  STATES,
  US_ARMY_STATES,
  US_ARMY_STATES_WITH_NAME,
} from 'composable'
import {
  ADDRESS_TYPE,
  MAX_LENGTH_ADDRESS,
  MAX_LENGTH_PHONE_NUMBER,
  MAX_LENGTH_USER_NAME,
  MAX_LENGTH_ZIP_CODE,
} from 'composable/components/checkout/constants'
import { SuggestionsDropDown } from 'composable/components/checkout/step1/Forms/suggestions-drop-down'
import { getAddressType } from 'composable/components/checkout/step1/utils'
import { CheckboxField } from 'composable/components/forms/checkbox-field'
import { InputField } from 'composable/components/forms/input-field'
import { SelectField } from 'composable/components/forms/select-field'
import useAdqAddress from 'composable/components/hooks/useAdqAddress'
import { VerifyLevel } from 'composable/components/types'
import { useFormat } from 'helpers/hooks/useFormat'
import { max } from 'lodash'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { CheckoutAddressType, validateAndFetchExperianClosestSuggestion } from 'frontastic/actions/experian'
import { useAccountCT } from 'frontastic/contexts'
import QasModal from 'frontastic/tastics/QasModal/QasModal'
import { AppartmentInfo, QasRecommendedAddress } from 'frontastic/tastics/QasModal/types/Account'
import { ConfirmModal } from '../../../../general/components/confirm-modal'
import { EditableContentCard, FormStatus } from '../../../../general/components/editable-content-card'
import { countries } from '../../../../general/constants'
import { InfoSectionCustom } from '../pages/order-detail-page'

export type AddressFormItems = {
  addressName: string
  isDefaultAddress: boolean
  firstName: string
  lastName: string
  email: string
  preferredLanguage: string
  apartment: string
  city: string
  state: string
  country: string
  region: string
  postalCode: string
  streetNumber: string
  streetName: string
  building: string
  title: string
  phone: string
  useAddressAsEntered?: boolean
  addressValidated?: boolean
}

export const trimInput = (input?: string) => input?.trimStart().replace(/\s+/g, ' ')

export const getPhoneformat = (phoneNumber: any) => {
  if (phoneNumber?.length === 10) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }

  if (phoneNumber?.length === 12) {
    return `${phoneNumber.slice(0, 2)} (${phoneNumber.slice(2, 5)}) ${phoneNumber.slice(5, 8)}-${phoneNumber.slice(
      8,
      12,
    )}`
  }

  return phoneNumber
}

export const AddressForm = ({
  customer,
  customerAddress,
  isEditOpen,
  updateButtonLabel,
  onCancel,
  typeForm,
}: {
  typeForm?: string
  onCancel?: () => void
  updateButtonLabel?: string
  customer: Account
  customerAddress: Address
  isEditOpen?: boolean
}) => {
  const { isOpen, onClose, onToggle } = useDisclosure()
  const { formatMessage } = useFormat({ name: 'common' })
  const { addAddress, setDefaultShippingAddress, removeAddress, updateAddress } = useAccountCT()
  const [addressConfirmationModal, setAddressConfirmationModal] = useState(false)
  const [enteredAddress, setEnteredAddress] = useState({})
  const [recommendedAddress, setRecommendedAddress] = useState<QasRecommendedAddress | false>(false)
  const [verifyLevel, setVerifyLevel] = useState<VerifyLevel>(VerifyLevel.NONE)
  const [status, setStatus] = useState<FormStatus>()

  const isLoadingRef = useRef(false)

  const {
    watch,
    getValues,
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<AddressFormItems>({
    resolver: yupResolver(AddressInformationFormSchema({ intl: { formatMessage } })),
    mode: 'onTouched',
  })

  const toast = useToast()

  const defaultShippingAddressId = customer.addresses?.find((address) => address.isDefaultShippingAddress)?.addressId
  const isDefault = defaultShippingAddressId && customerAddress.addressId === defaultShippingAddressId
  const address = {
    ...customerAddress,
    name: `${customerAddress.firstName ?? customer.firstName} ${customerAddress.lastName ?? customer.lastName}`,
    addressLine1: `${customerAddress.streetName}`,
    addressLine2: `${customerAddress.city}, ${customerAddress.state}, ${customerAddress.postalCode}`,
    phone: customerAddress.phone ?? '',
  }

  useEffect(() => {
    const customFields = customerAddress?.custom?.fields
    if (!customFields?.addressValidated && !customFields?.useAddressAsEntered) {
      setValue('addressValidated', false)
      setValue('useAddressAsEntered', true)
    } else {
      setValue('addressValidated', customFields.addressValidated)
      setValue('useAddressAsEntered', customFields.useAddressAsEntered)
    }
  }, [customerAddress])

  const deleteAddress = () => {
    removeAddress(address.addressId)
  }

  const setDefaultAddress = async (addressId: string) => {
    try {
      await setDefaultShippingAddress(addressId)
      toast({
        description: formatMessage({ id: 'account.dashboard.addresses.submit.defaultShipping.success' }),
        status: 'success',
        duration: 4000,
        isClosable: true,
        position: 'top',
      })
    } catch (e) {
      console.log(e)
      toast({
        description: formatMessage({ id: 'account.dashboard.addresses.submit.defaultShipping.error' }),
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      })
    }
  }

  const onSubmit = async (data: AddressFormItems) => {
    if (isLoadingRef.current) return

    isLoadingRef.current = true

    try {
      const country = data.country ?? customerAddress.country ?? countries[1].code

      if (typeForm === 'add') {
        const account = await addAddress({
          ...data,
          additionalStreetInfo: data.addressName,
          additionalAddressInfo: getAddressType(getCheckoutAddress(data)),
          isDefaultShippingAddress: data.isDefaultAddress,
          country,
        })
        if (customer.addresses?.length === 0) {
          setDefaultAddress(account.addresses[0].addressId)
        }
      }

      if (typeForm === 'edit') {
        await updateAddress({
          ...data,
          addressId: customerAddress.addressId,
          additionalStreetInfo: data.addressName,
          additionalAddressInfo: getAddressType(getCheckoutAddress(data)),
          isDefaultShippingAddress: data.isDefaultAddress,
          country,
        })
      }

      toast({
        description: formatMessage({ id: 'account.dashboard.addresses.submit.success' }),
        status: 'success',
        duration: 4000,
        isClosable: true,
        position: 'top',
      })

      setStatus('success')
      if (typeForm === 'add') {
        onCancel?.()
      }
    } catch (e) {
      setStatus('error')
      toast({
        description: formatMessage({ id: 'account.dashboard.addresses.submit.error' }),
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      })
    } finally {
      isLoadingRef.current = false
    }
  }

  const handleSubmit1 = (data: AddressFormItems) => {
    const country = data.country ?? customerAddress.country ?? countries[1].code
    const yourEntered = {
      id: '',
      additional_address_info: data?.apartment,
      additional_street_info: data?.apartment,
      first_name: '',
      last_name: '',
      phone_number: '',
      street_name: data?.streetName,
      street_number: data?.streetNumber,
      city: data?.city,
      state: data?.state,
      postcode: data?.postalCode,
      country: country,
    }

    setEnteredAddress(yourEntered)

    handleAddress(yourEntered).then((res) => {
      if (res.proceed === true) {
        if (res.overWriteAddress) {
          handleAddressSubmit(res.overWriteAddress)
        } else {
          handleAddressSubmit()
        }
      }
    })
  }
  const onError = () => setStatus('error')

  const streetName = watch('streetName')

  const streetNameRef = useRef(null)

  const setFormValues = (formattedAddress: ExperianAddressType) => {
    if (!formattedAddress) return
    reset({
      streetName: formattedAddress.address_line_1,
      apartment: formattedAddress.address_line_2,
      city: formattedAddress.locality,
      state: formattedAddress.region,
      country: formattedAddress.country,
      postalCode: formattedAddress.postal_code,
    })

    return {
      streetName: formattedAddress.address_line_1,
      additional_street_info: formattedAddress.address_line_2,
      city: formattedAddress.locality,
      state: formattedAddress.region,
      country: formattedAddress.country,
      postcode: formattedAddress.postal_code,
    }
  }

  const {
    suggestions,
    onStreetNameChange,
    handleSuggestionClick,
    selectedSuggestionIndex,
    setSelectedSuggestionIndex,
  } = useAdqAddress({
    setFormValues,
    streetNameRef,
  })

  const getState = () => {
    const cityVal: string = getValues('city')
    if (
      cityVal &&
      typeof cityVal === 'string' &&
      (cityVal.toLowerCase() === 'apo' || cityVal.toLowerCase() === 'fpo' || cityVal.toLowerCase() === 'dpo')
    ) {
      return US_ARMY_STATES_WITH_NAME
    } else {
      return STATES
    }
  }
  const handleSetAppartment = ({ additionalStreetInfo }: AppartmentInfo) => {
    setValue('apartment', additionalStreetInfo)
    setAddressConfirmationModal(false)
  }

  const handleAddressSubmit = (overwriteShippingFromModal?: CartAddress) => {
    if (overwriteShippingFromModal) {
      setValue('streetName', overwriteShippingFromModal.streetName)
      setValue('city', overwriteShippingFromModal.city)
      setValue('postalCode', overwriteShippingFromModal.postalCode)
      setValue('state', overwriteShippingFromModal.state)

      if (
        overwriteShippingFromModal?.useAddressAsEntered === false ||
        overwriteShippingFromModal?.useAddressAsEntered === true
      ) {
        setValue('useAddressAsEntered', overwriteShippingFromModal?.useAddressAsEntered)
      }
      if (
        overwriteShippingFromModal?.addressValidated === false ||
        overwriteShippingFromModal?.addressValidated === true
      )
        setValue('addressValidated', overwriteShippingFromModal?.addressValidated)
    }
    handleSubmit(onSubmit, onError)?.()
  }
  const handleAddress = async (checkoutStateAddress: CheckoutAddressType) => {
    const experianSuggestedAddress = await validateAndFetchExperianClosestSuggestion(checkoutStateAddress)
    const isAddressesSame = isAddressValidate(experianSuggestedAddress?.confidence)

    if (!isAddressesSame) {
      setRecommendedAddress({
        pickListItem: experianSuggestedAddress?.pickListItems || [],
        addrLineItem: experianSuggestedAddress?.addrLineItem,
      })
      setVerifyLevel(experianSuggestedAddress?.confidence)
      setAddressConfirmationModal(true)

      return { proceed: false, overWriteAddress: null }
    } else {
      const address = experianSuggestedAddress?.addrLineItem?.address || null

      let overWriteAddress: CartAddress | null = null
      if (address) {
        overWriteAddress = {
          ...ctAddressRefinement(address),
          useAddressAsEntered: false,
          addressValidated: true,
        }
      }
      return { proceed: true, overWriteAddress: overWriteAddress }
    }
  }

  const customRegister = (name: any) => {
    const { onChange, ...rest } = register(name)

    return {
      onChange: (e) => {
        onChange(e)
        setValue(name, trimInput(e.target.value))
      },
      ...rest,
    }
  }

  if (!customer) return <></>

  return (
    <Box key={address.addressId} mb={5}>
      <QasModal
        modalOpen={recommendedAddress && addressConfirmationModal}
        modalOnClose={() => {
          setAddressConfirmationModal(false)
        }}
        recommendedAddress={recommendedAddress ? recommendedAddress : null}
        enteredAddress={enteredAddress as any}
        handleSetAppartmentInfo={handleSetAppartment}
        handleStep1Submit={handleAddressSubmit}
        verifyLabel={verifyLevel}
        setAddressConfirmationModal={setAddressConfirmationModal}
        setAddressConfirmationDecision={() => {}}
        modalTitle={formatMessage({ id: 'account.dashboard.addresses.validationModal.title' })}
      />
      <EditableContentCard
        isLoading={isLoadingRef.current}
        size="Large"
        onCancel={onCancel}
        editModeOn={isEditOpen ?? false}
        deleteFn={onToggle}
        title={customerAddress.additionalStreetInfo}
        editTitle={
          typeForm === 'add'
            ? formatMessage({
                id: 'account.dashboard.addresses.addAddress',
              })
            : formatMessage({
                id: 'account.dashboard.addresses.edit',
              })
        }
        readOnly={
          <>
            <Box gap={1}>
              <InfoSectionCustom.Content
                content={[
                  address.name,
                  address.addressLine1,
                  address.apartment,
                  address.addressLine2,
                  getPhoneformat(address.phone),
                ]}
              />
            </Box>
            <Spacer h={4} />
            <Box
              onClick={(e) => {
                e.preventDefault()
                !isDefault && setDefaultAddress(customerAddress.addressId)
              }}
            >
              <Radio value={'id'} size="lg" isChecked={isDefault}>
                <Text textStyle={'callouts-blockquote-75'} fontSize="sm">
                  {formatMessage({
                    id: 'account.dashboard.addresses.defaultCheckbox',
                  })}
                </Text>
              </Radio>
            </Box>
          </>
        }
        isDirty={isDirty}
        status={status}
        setStatus={setStatus}
        onSubmit={handleSubmit(handleSubmit1, onError)}
        resetEditForm={reset}
        updateButtonLabel={updateButtonLabel ?? formatMessage({ id: 'action.updateInformation' })}
        edit={
          <Stack spacing={4} direction="column">
            <InputField
              label={formatMessage({
                id: 'account.dashboard.addresses.label.addressName',
              })}
              inputProps={{
                defaultValue: address.additionalStreetInfo,
                ...customRegister('addressName'),
                placeholder: 'Nickname',
                maxLength: MAX_LENGTH_USER_NAME,
              }}
              error={errors.addressName}
            />
            <Stack direction={{ base: 'column', md: 'row' }}>
              <InputField
                label={formatMessage({
                  id: 'checkout.shippingAddressForm.label.firstName',
                })}
                inputProps={{
                  defaultValue: address.firstName ?? customer.firstName ?? '',
                  ...customRegister('firstName'),
                  placeholder: `${formatMessage({
                    id: 'checkout.shippingAddressForm.label.firstName',
                  })} *`,
                  maxLength: MAX_LENGTH_USER_NAME,
                }}
                error={errors.firstName}
                isRequired
              />
              <InputField
                label={formatMessage({
                  id: 'checkout.shippingAddressForm.label.lastName',
                })}
                inputProps={{
                  defaultValue: address.lastName ?? customer.lastName ?? '',
                  ...customRegister('lastName'),
                  placeholder: `${formatMessage({
                    id: 'checkout.shippingAddressForm.label.lastName',
                  })} *`,
                  maxLength: MAX_LENGTH_USER_NAME,
                }}
                error={errors.lastName}
                isRequired
              />
            </Stack>
            <Flex alignItems={'baseline'} gap={1}>
              <SuggestionsDropDown
                reset={reset}
                inputProps={{
                  maxLength: MAX_LENGTH_ADDRESS,
                  defaultValue: address.streetName ?? '',
                }}
                fieldName="streetName"
                streetNameRef={streetNameRef}
                register={customRegister}
                onStreetNameChange={onStreetNameChange}
                setSelectedSuggestionIndex={setSelectedSuggestionIndex}
                selectedSuggestionIndex={selectedSuggestionIndex}
                suggestions={suggestions}
                handleSuggestionClick={handleSuggestionClick}
                setValue={setValue}
                currentStreetName={streetName}
                errors={errors}
              />
            </Flex>
            <InputField
              label={formatMessage({
                id: 'checkout.shippingAddressForm.label.additionalStreetInfo',
              })}
              inputProps={{
                maxLength: MAX_LENGTH_ADDRESS,
                defaultValue: address.apartment ?? '',
                ...customRegister('apartment'),
                placeholder: formatMessage({
                  id: 'checkout.shippingAddressForm.label.additionalStreetInfo',
                }),
              }}
              error={errors.apartment}
            />

            <Stack direction={{ base: 'column', md: 'row' }}>
              <InputField
                label={formatMessage({
                  id: 'checkout.shippingAddressForm.label.city',
                })}
                inputProps={{
                  maxLength: MAX_LENGTH_ADDRESS,
                  defaultValue: address.city ?? '',
                  ...customRegister('city'),
                  placeholder: formatMessage({
                    id: 'checkout.shippingAddressForm.label.city',
                  }),
                }}
                error={errors.city}
                isRequired
                showInfoIcon
              />
              <SelectField
                label={formatMessage({
                  id: 'checkout.shippingAddressForm.label.state',
                })}
                selectProps={{
                  defaultValue: address.state ?? '',
                  ...register('state'),
                }}
                error={errors.state}
                isRequired
              >
                <option defaultValue={''} disabled value={''}>
                  {formatMessage({
                    id: 'action.select',
                  })}
                </option>
                <>
                  {getState().map((state) => {
                    const label = state?.intlId ? formatMessage({ id: state.intlId }) : state.name
                    return (
                      <option title={label} aria-label={label} key={state.code} value={state.code}>
                        {label}
                      </option>
                    )
                  })}
                </>
              </SelectField>
            </Stack>

            <Stack direction={{ base: 'column', md: 'row' }}>
              <InputField
                label={formatMessage({
                  id: 'checkout.shippingAddressForm.label.postcode',
                })}
                inputProps={{
                  maxLength: MAX_LENGTH_ZIP_CODE,
                  defaultValue: address.postalCode ?? '',
                  ...customRegister('postalCode'),
                  placeholder: formatMessage({
                    id: 'checkout.shippingAddressForm.label.postcode',
                  }),
                }}
                error={errors.postalCode}
                isRequired
              />
              <InputField
                label={formatMessage({
                  id: 'checkout.shippingAddressForm.label.phoneNumber',
                })}
                inputProps={{
                  defaultValue: address.phone ?? '',
                  ...customRegister('phone'),
                  maxLength: MAX_LENGTH_PHONE_NUMBER,
                  placeholder: '000 000 0000',
                }}
                error={errors.phone}
                isRequired
              />
            </Stack>

            <CheckboxField
              content={formatMessage({
                id: 'account.dashboard.addresses.defaultCheckbox',
              })}
              checkboxProps={{
                ...register('isDefaultAddress'),
                defaultChecked: customerAddress.addressId ? customerAddress.isDefaultShippingAddress : true,
              }}
            />
          </Stack>
        }
      />

      <ConfirmModal
        size="xs"
        isOpen={isOpen}
        onClose={onClose}
        title={formatMessage({
          id: 'account.dashboard.addresses.delete.title',
        })}
        description={formatMessage({
          id: 'account.dashboard.addresses.delete.description',
        })}
        actionButtonLabel={formatMessage({
          id: 'account.dashboard.addresses.delete.buttonLabel',
        })}
        cancelButtonLabel={formatMessage({ id: 'action.cancel' })}
        onConfirm={deleteAddress}
      />
    </Box>
  )
}

const AddressInformationFormSchema = (deps: { intl: { formatMessage: (params: unknown) => string } }) => {
  const { intl } = deps
  return yup.object().shape({
    addressName: yup.string().matches(/^[a-zA-Z0-9 ]*$/, intl.formatMessage({ id: 'validation.address' })),

    firstName: yup
      .string()
      .transform((value) => value.trim())
      .required(intl.formatMessage({ id: 'checkout.step1.validationForm.firstName.isRequired' }))
      .matches(
        /^[a-zA-Z]+(?:[ -][a-zA-Z]+)*$/,
        intl.formatMessage({ id: 'checkout.step1.validationForm.firstName.isValid' }),
      ),

    lastName: yup
      .string()
      .transform((value) => value.trim())
      .required(intl.formatMessage({ id: 'checkout.step1.validationForm.lastName.isRequired' }))
      .matches(
        /^[a-zA-Z]+(?:[ -][a-zA-Z]+)*$/,
        intl.formatMessage({ id: 'checkout.step1.validationForm.lastName.isValid' }),
      ),

    streetName: yup
      .string()
      .trim()
      .required(intl.formatMessage({ id: 'checkout.step1.validationForm.address.isRequired' }))
      .matches(/^[a-zA-Z0-9// ]*$/, intl.formatMessage({ id: 'validation.address' })),

    apartment: yup.string().matches(/^[a-zA-Z0-9// ]*$/, intl.formatMessage({ id: 'validation.address' })),

    city: yup
      .string()
      .matches(/^[a-zA-Z0-9 ]*$/, intl.formatMessage({ id: 'validation.address' }))
      .required(intl.formatMessage({ id: 'checkout.step1.validationForm.city.isRequired' })),

    state: yup
      .string()
      .required(intl.formatMessage({ id: 'checkout.step1.validationForm.state.isRequired' }))
      .matches(/^[a-zA-Z0-9 ]*$/, intl.formatMessage({ id: 'checkout.step1.validationForm.state.isValid' })),

    postalCode: yup
      .string()
      .required(intl.formatMessage({ id: 'checkout.step1.validationForm.postcode.isRequired' }))
      .matches(
        /^\d{5}(-[A-Za-z0-9]{4})?$/,
        intl.formatMessage({ id: 'checkout.step1.validationForm.postcode.isValid' }),
      ),
    phone: yup
      .string()
      .required(intl.formatMessage({ id: 'checkout.step1.validationForm.phoneNumber.isRequired' }))
      .min(10, intl.formatMessage({ id: 'checkout.step1.validationForm.phoneNumber.isValid', values: { digits: 10 } }))
      .max(10, intl.formatMessage({ id: 'checkout.step1.validationForm.phoneNumber.isValid', values: { digits: 10 } }))
      .matches(/^[0-9]*$/, intl.formatMessage({ id: 'checkout.step1.validationForm.phoneNumber.isValid' })),
  })
}
