import { Dispatch, SetStateAction } from 'react'
import { StyleProps } from '@chakra-ui/react'
import { CartAddress } from '@Types/account/Address'
import { Order } from '@Types/cart/Order'
import { ShippingMethod } from '@Types/cart/ShippingMethod'
import { IconType } from 'react-icons'
import { QasRecommendedAddress, VerifyLevel } from 'frontastic/tastics/QasModal/types/Account.d'

export { VerifyLevel }
export type CheckoutStateType = {
  config: {
    billingIsShipping: boolean
    saveNewAddressToMyAccount: boolean
  }
  customer: {
    email: string
  }
  shipping_address: {
    id: string
    additional_address_info: string
    first_name: string
    last_name: string
    phone_number: string
    street_name: string
    street_number: string
    city: string
    state: string
    postcode: string
    country: string
    additional_street_info: string
    useAddressAsEntered?: boolean
    addressValidated?: boolean
    apartment?: string
  }
  billing_address: {
    id: string
    additional_address_info: string
    first_name: string
    last_name: string
    phone_number: string
    street_name: string
    street_number: string
    city: string
    state: string
    postcode: string
    country: string
    additional_street_info: string
    useAddressAsEntered?: boolean
    addressValidated?: boolean
    apartment?: string
  }
  flow: Record<string, any>
  shipping_method?: ShippingOption
  creditCardPayment: CreditCardPayment
  selectedSavedCard: { card: any; cvv: any }
  rcPayments: GiftCardOrRewardCertificatePayment[]
  gcPayments: GiftCardOrRewardCertificatePayment[]
}
export interface PaypalResponseDetails {
  email: string
}
export interface AfterPayResponseDetails {
  token: string
}
export interface CheckoutContextInterface {
  checkoutState: CheckoutStateType
  handlingFees?: number
  checkoutStateInitial: CheckoutStateType
  cleanInitialShippingAddress: () => void
  setCheckoutState: Dispatch<SetStateAction<CheckoutStateType>>
  validation: CheckoutValidationHandler
  validationResults: Record<string, boolean>
  setValidationResults: Dispatch<SetStateAction<Record<string, boolean>>>
  // response: {
  //   checkout: CreateMyOrderFromCartMutation['createMyOrderFromCart'] | undefined;
  //   payment: PaymentResponse | undefined;
  // };
  isLoading: boolean
  // __setCheckoutResponse: Dispatch<SetStateAction<CreateMyOrderFromCartMutation['createMyOrderFromCart'] | undefined>>;
  // __setPaymentResponse: Dispatch<SetStateAction<PaymentResponse | undefined>>;
  __setIsLoading: Dispatch<SetStateAction<boolean>>
  // cartSnapshot: Partial<CartFragment> | undefined;
  // __setCartSnapshot: Dispatch<SetStateAction<Partial<CartFragment> | undefined>>;
  // shippingOptions: {
  //   list: ShippingOption[];
  //   setSelected: (id: string) => void;
  //   clearSelection: () => void;
  //   selected: (ShippingOption & { rate?: Money }) | undefined;
  // };
  isLoadingStep1: boolean
  setIsLoadingStep1: Dispatch<SetStateAction<boolean>>
  savedShippingAddresses: {
    setSelected: (id: string) => void
    clearSelection: () => void
    selected: CartAddress | undefined
  }
  payment: {
    stripeData?: StripeData
    setStripeData: Dispatch<SetStateAction<StripeData | undefined>>
  }
  paymentHandler: PaymentMethodHandlerState
  // __createPaymentIntent: (data: StripePaymentIntentParams) => Promise<{ data: StripePaymentIntent }>
  addressConfirmationModal: boolean
  setAddressConfirmationModal: Dispatch<SetStateAction<boolean>>
  addressConfirmationDecision: boolean
  setAddressConfirmationDecision: Dispatch<SetStateAction<boolean>>
  billingAddressConfirmationDecision: boolean
  setBillingAddressConfirmationDecision: Dispatch<SetStateAction<boolean>>
  billingAdressConfirmationModal: boolean
  setBillingAddressConfirmationModal: Dispatch<SetStateAction<boolean>>
  recommendedAddress: QasRecommendedAddress | false
  setRecommendedAddress: Dispatch<SetStateAction<QasRecommendedAddress | false>>
  setVerifyLevel?: Dispatch<SetStateAction<VerifyLevel>>
  verifyLevel: VerifyLevel
  isCreditCardFormSubmitted: boolean
  setIsCreditCardFormSubmitted: Dispatch<SetStateAction<boolean>>
  flexResponse: string
  setFlexResponse: Dispatch<SetStateAction<string>>
  microFormRef: any
  isCheckoutPageLoading: boolean
  setCheckoutPageLoading: Dispatch<SetStateAction<boolean>>
  totalGCRCValue: number
  paypalResponseDetails: PaypalResponseDetails
  afterPayResponseDetails: AfterPayResponseDetails
  setPaypalResponseDetails: Dispatch<SetStateAction<PaypalResponseDetails>>
  isUtagCalledRef1: any
  isBillingAddressLoaded: boolean
  isOpenPaypalBillingAddress: boolean
  setIsOpenPaypalBillingAddress: Dispatch<SetStateAction<boolean>>
}

export type CheckoutValidationFn = () => Promise<boolean>

export type CheckoutValidationList = Partial<Record<string, CheckoutValidationFn>>

export interface StripeData {
  customerId?: string
  paymentMethodId?: string
}

export type CheckoutValidationHandler = {
  register: (key: string, validation: CheckoutValidationFn) => void
  unregister: (key: string) => void
  run: (key?: string) => Promise<boolean>
  list: CheckoutValidationList
}

export type PaymentResponse<T = any> = {
  ctPaymentId: string
  [key: string]: any
}

export type ShippingOption = {
  id: string
  name: string
  description?: string
  zoneRates: ShippingMethod['rates']
  custom?: {
    fields?: {
      deliveryInBusinessDays: number
    }
  }
}

export type CreditCardPayment = {
  creditCardNumber?: string
  creditCardUserName?: string
  securityCode?: string
  expiryDate?: string
  cardType?: string
  saveCardToAccount?: boolean
  token?: string
  isValid?: boolean
}
export type GiftCardOrRewardCertificatePayment = {
  giftCardNumber: string
  giftCardPin: string
  price: number
  isApplied: boolean
  displayPrice?: string
  displayExpiryDate?: string
  isInactive?: boolean
  isAlreadyUsed?: boolean
}

export type ShippingOptionsList = Record<ShippingOption['id'], ShippingOption>

export type SavedShippingAddress = CheckoutStateType['shipping_address']

export type SavedShippingAddressesList = Record<SavedShippingAddress['id'], SavedShippingAddress>

export interface PaymentMethod {
  key: string
  title: string
  icon?: IconType
  imageUrl?: string
  disabled?: boolean
}

export interface PaymentMethodHandlerState {
  register: (paymentMethod: PaymentMethod) => void
  unregister: (key: string) => void
  select: (key?: string) => void
  selected: PaymentMethod | undefined
  list: PaymentMethod[]
  paymentMethodExtraData?: Record<string, any>
  setPaymentMethodExtraData: Dispatch<SetStateAction<Record<string, any> | undefined>>
}

export type FormStatus = 'success' | 'error' | undefined

export type Thumbnails = {
  id: string
  image_url?: string
  swatch_url: string
  color_name?: string
  alt?: string
}

export interface TextSelectorOption {
  value: string
  inStock?: boolean
  isBackordered?: boolean
  priceGroup?: any
}

export interface SwatchSelectorOption extends TextSelectorOption {
  swatch: string
  swatchUrl: string
  colorDisplayName: string | undefined
}

export interface SelectorProps {
  isSelected: boolean
  isDisabled: boolean
  setSelection: Dispatch<SetStateAction<string>>
}

export interface TextSelectorProps extends SelectorProps {
  option: TextSelectorOption
  isFocusable?: boolean
  isStoreDataLoading: boolean
}

export interface SwatchSelectorProps extends SelectorProps {
  option: SwatchSelectorOption
  renderTooltip?: boolean
  wrapperStyle?: StyleProps
  isHovered?: boolean
  isStoreDataLoading: boolean
  isMobile?: boolean
}

export type SwatchSelectorConfiguration = {
  attribute: {
    label: string
    value: string
    colorDisplayName: string | undefined
  }
  options: SwatchSelectorOption[]
}

export type SwatchSelectorGrouped = {
  [key: string]: SwatchSelectorOption[]
}

export interface PromotionDatas {
  id: string
  name: string
}
//TODO : Remove this comment later
