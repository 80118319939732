const pageType = (relativePath: string, pageWiseData?: any) => {
  const breadCrumbs = pageWiseData?.breadCrumbs
  if (!!breadCrumbs) {
    if (breadCrumbs.find((item: string) => item === 'Brands') && relativePath === 'c') {
      return 'brand'
    }
    const breadCrumbsWithoutHome = breadCrumbs?.find((item: string) => item === 'Home')
      ? breadCrumbs.slice(1)
      : [...breadCrumbs]

    if (!!breadCrumbsWithoutHome && breadCrumbsWithoutHome.length > 2 && relativePath === 'c') {
      return 'subsubcategory'
    }
  }
  const isSearchPage = pageWiseData?.isPlpSearchPage
  if (!!isSearchPage) {
    return 'search'
  }

  const isNoResultsSearchPage = pageWiseData?.isNoResultsPage
  if (!!isNoResultsSearchPage) {
    return 'nosearch'
  }

  const isSuccess = pageWiseData?.checkoutStep && pageWiseData?.checkoutStep === 'success'
  if (isSuccess) {
    return 'purchase'
  }

  switch (relativePath) {
    case 'static':
      return 'static'
    case 'c':
      return 'plp'
    case 'p':
      return 'product'
    case '':
      return 'index'
    case 'cart':
      return 'cart'
    case 'account':
      return 'myAccount'
    case 'checkout':
      return 'checkout'
    default:
      return 'error'
  }
}

export const parsePathFragments = (routerUrlPath: string) => {
  const relativeUrlPathList = routerUrlPath.split('/').slice(1)
  const pathFragment =
    relativeUrlPathList[0]?.indexOf('?') > -1 ? relativeUrlPathList[0].split('?')[0] : relativeUrlPathList[0]
  return pathFragment
}

export const findPageType = (routerUrlPath: string, pageWiseData?: any) => {
  const pathFragment = parsePathFragments(routerUrlPath)
  const page = pageType(pathFragment, pageWiseData)
  return page
}

export const breadCrumbsBuilder = (routerUrlPath: string, pageType: string) => {
  const urlFragmentsList = routerUrlPath?.split('/').slice(1)
  if (urlFragmentsList) {
    switch (pageType) {
      case 'static':
        return pageBreadCrumbBuilder(urlFragmentsList)
      case 'index':
        return ['home']
      case 'myAccount':
        return pageBreadCrumbBuilder(urlFragmentsList)
    }
  }
  return []
}

export const pageBreadCrumbBuilder = (urlFragmentsList: string[]) => {
  const staticPageFragmentsList = urlFragmentsList
    .slice(1)
    .map((i) => i.split('#').flat())
    .flat()
  return staticPageFragmentsList
}

export const convertToDollarAmount = (centAmount: number, fractionDigits: number): string => {
  const divisor = Math.pow(10, fractionDigits)
  const dollarAmount = centAmount / divisor
  return dollarAmount ? String(dollarAmount) : ''
}
