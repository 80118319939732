import { StyleConfig } from '@chakra-ui/react'
import { fontSize } from '../../figma-tokens'

export const Button: StyleConfig = {
  baseStyle: {
    borderRadius: '6px',
    paddingTop: 2.5,
    paddingBottom: 2.5,
  },
  sizes: {
    xs: {
      minWidth: '57px',
      height: '6',
      paddingLeft: 2,
      paddingRight: 2,
      fontSize: 12,
    },
    sm: {
      minWidth: '71px',
      height: '8',
      paddingLeft: 3,
      paddingRight: 3,
      fontSize: fontSize.sm,
    },
    md: {
      minWidth: '86px',
      height: '10',
      paddingLeft: 4,
      paddingRight: 4,
      fontSize: fontSize.sm,
    },
    lg: {
      minWidth: '109px',
      height: '12',
      paddingLeft: 6,
      paddingRight: 6,
      fontSize: fontSize.sm,
    },
  },
  variants: {
    solid: {
      bgColor: 'primary',
      opacity: '100%',
      gap: 2,
      color: 'text.primary-inver',
      _hover: {
        bgColor: { base: 'primary', lg: 'primary.600' },
        color: 'text.primary-inver',
        opacity: '100%',
      },
      _active: {
        bgColor: 'primary.700',
        color: 'white',
      },
      _disabled: {
        bgColor: 'primary.300',
        color: 'text.primary-inver',
      },
    },
    'solid-alt': {
      bgColor: 'surface.primary',
      opacity: '100%',
      gap: 2,
      color: 'primary',
      _hover: {
        bgColor: 'surface.primary',
        color: 'primary',
        opacity: { base: '100%', lg: '80%' },
      },
      _active: {
        bgColor: 'surface.primary',
        color: 'secondary',
      },
      _disabled: {
        bgColor: 'primary.300',
        color: 'text.muted',
      },
    },
    outline: {
      bgColor: 'transparent',
      opacity: '100%',
      color: 'text.primary',
      border: '1px',
      borderColor: 'primary',
      _hover: {
        bgColor: { base: 'white', lg: 'surface.highlight' },
        color: 'text.primary',
      },
      _active: {
        bgColor: 'brand.accent',
        color: 'text.primary',
      },
      _disabled: {
        bgColor: 'text.disabled',
        color: 'text.primary-inver',
        borderColor: 'text.disabled',
      },
    },
    'outline-black': {
      color: 'text',
      borderColor: '#E2E8F0',
      borderWidth: '1px',
      borderStyle: 'solid',
      _hover: {
        bg: { lg: 'muted' },
        borderColor: 'primary',
        _disabled: {
          color: 'muted',
          borderColor: 'muted',
        },
      },
      _active: {
        bg: 'accent',
        borderColor: 'accent',
      },
      _disabled: {
        opacity: 1,
        color: 'text-muted',
        borderColor: 'text-muted',
      },
    },
    'outline-alt': {
      bgColor: 'primary',
      opacity: '100%',
      color: 'surface.primary',
      border: '1px',
      borderColor: 'surface.primary',
      _hover: {
        bgColor: { base: 'primary', lg: 'primary.500' },
        color: 'surface.primary',
      },
      _active: {
        bgColor: 'surface.primary',
        color: 'primary',
      },
      _disabled: {
        bgColor: 'surface.highlight',
        color: 'text.muted',
        borderColor: 'text.disabled',
      },
    },
    ghost: {
      textDecoration: 'underline',
      bgColor: 'white',
      opacity: '100%',
      color: 'primary',
      _hover: {
        bgColor: { base: 'white', lg: 'surface.highlight' },
        color: 'primary',
      },
      _active: {
        bgColor: 'white',
        color: 'primary',
      },
      _disabled: {
        bgColor: 'white',
        color: 'text.disabled',
      },
    },
    'ghost-alt': {
      textDecoration: 'underline',
      bgColor: 'primary',
      opacity: '100%',
      color: 'surface.primary',
      _hover: {
        bgColor: { base: 'primary', lg: 'brand.muted' },
        color: { base: 'surface.primary', lg: 'primary' },
      },
      _active: {
        bgColor: 'primary',
        color: 'surface.primary',
      },
      _disabled: {
        bgColor: 'primary',
        color: 'text.disabled',
      },
    },
    danger: {
      color: 'white',
      background: 'bg-danger',
    },
    success: {
      color: 'white',
      background: 'bg-success',
    },
    muted: {
      color: 'fg-btn-muted',
      background: 'bg-btn-muted',
    },
    link: {
      color: 'text-muted',
      fontWeight: '400',
      fontSize: '0.875rem',
    },
  },
}
