import { useState } from 'react'
import { FormControl, FormLabel, HStack, Input, Stack, Text, FormErrorMessage } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import useOnClickOutside from 'helpers/hooks/useOnClickOutside'
import { IoLocationOutline } from 'react-icons/io5'

type NewValue = string | { global_address_key: string; text: string } | false

export const SuggestionsDropDown = ({
  reset,
  inputProps = {},
  streetNameRef,
  register,
  onStreetNameChange,
  setSelectedSuggestionIndex,
  selectedSuggestionIndex,
  suggestions,
  handleSuggestionClick,
  setValue,
  currentStreetName,
  errors,
  fieldName = 'street_name',
}) => {
  const intl = useFormat({ name: 'common' })
  const { onChange, ...rest } = register(fieldName)

  const [newStreetName, setNewStreetName] = useState<NewValue>(false)
  const [isSelectingSuggestion, setIsSelectingSuggestion] = useState(false)
  const error = errors[fieldName]

  useOnClickOutside(streetNameRef, () => {
    updateNewValue()
  })

  function handleSuggestionSelection(suggestion: { format: string; suggestion: string }) {
    setIsSelectingSuggestion(true)
    const streetName = suggestion?.suggestion?.split(',')[0] || ''

    setNewStreetName(streetName)
    handleSuggestionClick(suggestion.format)

    reset({
      apartment: '',
      city: '',
      state: '',
      postcode: '',
      country: '',
      additional_street_info: '',
      additional_address_info: '',
      useAddressAsEntered: false,
      addressValidated: false,
    })

    setTimeout(() => setIsSelectingSuggestion(false), 0) // Reset the flag after state updates
  }

  function updateNewValue() {
    if (newStreetName && newStreetName !== currentStreetName) {
      setValue(fieldName, newStreetName)
    }
  }

  return (
    <FormControl isRequired isInvalid={Boolean(error)}>
      <FormLabel
        textStyle={'heading-desktop-75'}
        requiredIndicator={
          <Text as="span" textStyle={'heading-desktop-75'}>
            *
          </Text>
        }
        mb={2}
        color={'text.primary'}
        display="flex"
        alignItems="center"
      >
        <Text textStyle={'heading-desktop-75'}>
          {intl.formatMessage({
            id: 'checkout.shippingAddressForm.label.streetName',
          })}
        </Text>
      </FormLabel>
      <Input
        {...rest}
        {...inputProps}
        onChange={(e) => {
          if (!isSelectingSuggestion) {
            reset({
              [fieldName]: e.target.value,
            })
            onChange(e)
            const value = e.target.value
            onStreetNameChange(value)
            setNewStreetName(value)
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'ArrowUp') {
            e.preventDefault()
            setSelectedSuggestionIndex((prevIndex) => Math.max(prevIndex - 1, 0))
          } else if (e.key === 'ArrowDown') {
            e.preventDefault()
            setSelectedSuggestionIndex((prevIndex) => Math.min(prevIndex + 1, suggestions.length - 1))
          } else if (e.key === 'Enter') {
            e.preventDefault()
            if (selectedSuggestionIndex !== -1) {
              const selectedSuggestion = suggestions[selectedSuggestionIndex]
              handleSuggestionSelection(selectedSuggestion)
            }
          }
        }}
        isRequired
        placeholder={`${intl.formatMessage({
          id: 'checkout.shippingAddressForm.label.streetName',
        })} *`}
      />
      <FormErrorMessage mt={2}>{error?.message}</FormErrorMessage>

      {suggestions.length > 0 && (
        <Stack ref={streetNameRef} border="1px solid" borderRadius="md" borderColor="shading.400" mt={2}>
          {suggestions?.map((suggestion, i) => (
            <HStack
              key={i}
              px={4}
              py={2}
              cursor={suggestion.format ? 'pointer' : 'not-allowed'}
              onClick={
                suggestion?.format
                  ? () => {
                      handleSuggestionSelection(suggestion)
                    }
                  : undefined
              }
              _hover={{ backgroundColor: 'surface.highlight' }}
              _focus={{ backgroundColor: 'surface.highlight' }}
              bg={selectedSuggestionIndex === i && 'surface.highlight'}
            >
              <IoLocationOutline />
              <Text flex={1} fontSize="14px">
                {suggestion?.suggestion || suggestion?.text}
              </Text>
            </HStack>
          ))}
        </Stack>
      )}
    </FormControl>
  )
}
