import Script from 'next/script'
import { PAYPAL_CLIENT_ID, PAYPAL_SRC_URL } from 'composable/components/general/constants'

const PayPalProvider = ({ children }) => {
  const src = `${PAYPAL_SRC_URL}&client-id=${PAYPAL_CLIENT_ID}`

  return (
    <>
      <Script
        src={src}
        onError={(e) => {
          console.error('Error loading PayPal SDK:', e)
        }}
      />
      {children}
    </>
  )
}

export default PayPalProvider
